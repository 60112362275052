import { instance } from "./authAPI";
import Cookies from "universal-cookie";
import { PeriodDetailFilterType } from "../components/DashboardPortal/AirShipping/AirShippingTab";
import { EditContainerSubmitData } from "../components/DashboardPortal/EditContainerModal/EditContainerModal";
import { generateRangeDate } from "../components/DashboardPortal/helpers/helpers";
import {
    AddShipmentTrackingDataType,
    ContainerDetailsType,
    CreateFileAxiosDataType,
    CreateFileAxiosRequestType,
    CreatePortalContainerType,
    DetailFileObjectType,
    DownloadProductsFileResponseType,
    GetBrokerApproveListResponseType,
    GetContainersAxiosRequestType,
    GetContainersNumbersResponseType,
    GetDetailsAxiosRequestType,
    GetInvoiceListAxiosRequestType,
    GetOrdersAxiosRequest,
    GetOrdersFilterDataResponseType,
    GetTaxRuleAxiosRequestType,
    InvoiceDetailType,
    InvoiceFiltersData,
    ShipmentsFilterDataType,
    TaxRuleUpdateType,
} from "../redux/types/PortalTypes/portal.types";

export const packingPortalAPI = {
    getOrdersList(type: string, cookies: Cookies, productFcFilter: string, productSortFilter: string, tabState: string) {
        return instance.get<unknown, GetOrdersAxiosRequest>(
            `/ff/orders_list/${tabState}/${type}/?${productFcFilter ? `search=${productFcFilter}` : ""}${productSortFilter ? `&ordering=${productSortFilter}` : "&ordering=-created_at"}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getOrdersListAll(type: string, cookies: Cookies, productFcFilter: string, productSortFilter: string, items: number, offset: number, tabState: string) {
        return instance.get<unknown, GetOrdersAxiosRequest>(
            `/ff/orders_list/${tabState}/${type}/?limit=${items ? items : 20}&offset=${offset}${productFcFilter ? `&search=${window.encodeURIComponent(productFcFilter)}` : ""}${productSortFilter ? `&ordering=${productSortFilter}` : "&ordering=-created_at"}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    createContainer(data: CreatePortalContainerType, cookies: Cookies) {
        return instance.post(`/ff/create_container/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getContainersList(id: string, type: string, cookies: Cookies, searchFilter: string, statusFilter: string, sortFilter: string, tabState: string) {
        return instance.get<unknown, GetContainersAxiosRequestType>(
            `/ff/containers_list/${tabState}/${type}/?${searchFilter ? `search=${searchFilter}` : "search="}${statusFilter !== "All" ? `&closed=${statusFilter}` : ""}${sortFilter ? `&ordering=${sortFilter}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getContainersListAll(
        id: string,
        type: string,
        cookies: Cookies,
        searchFilter: string,
        statusFilter: string,
        sortFilter: string,
        items: number,
        offset: number,
        tabState: string
    ) {
        return instance.get<unknown, GetContainersAxiosRequestType>(
            `/ff/containers_list/${tabState}/${type}/?limit=${items ? items : 20}&offset=${offset}${searchFilter ? `&search=${window.encodeURIComponent(searchFilter)}` : ""}${statusFilter !== "All" ? `&closed=${statusFilter}` : ""}${sortFilter ? `&ordering=${sortFilter}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    addOrderToContainer(listOrders: string[], containerId: string, cookies: Cookies) {
        return instance.patch(
            `/ff/add_order_to_container/${containerId}/`,
            { orders: listOrders },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    updateContainer(containerId: string, data: EditContainerSubmitData, cookies: Cookies) {
        return instance.patch(`/ff/update_container/${containerId}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteContainer(containerId: string, cookies: Cookies) {
        return instance.delete(`/ff/delete_container/${containerId}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteOrderFromContainer(orderId: string, containerId: string, cookies: Cookies) {
        return instance.patch(
            `/ff/remove_order_from_container/${containerId}/`,
            { order: orderId },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    closeContainer(containerId: string, status: boolean, cookies: Cookies) {
        return instance.patch(
            `/ff/close_container/${containerId}/`,
            { is_closed: status },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    createPackingListFile(containerId: string, cookies: Cookies) {
        return instance.get<unknown, CreateFileAxiosRequestType>(`/ff/packing_list_file/${containerId}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    downloadProductsList(cookies: Cookies, productList: DetailFileObjectType[], tabState: string) {
        return instance.post<unknown, DownloadProductsFileResponseType>(
            "/ff/products_list_file/",
            { id_list: productList, country_code: tabState },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getContainersForDropdown(cookies: Cookies, type: string, tabState: string) {
        return instance.get<unknown, GetContainersNumbersResponseType>(`/ff/container_numbers_list/${tabState}/${type}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOrdersFiltersData(cookies: Cookies, type: string, tabState: string) {
        return instance.get<unknown, GetOrdersFilterDataResponseType>(`/ff/orders_filter_data_list/${tabState}/${type}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getContainerDetails(cookies: Cookies, id: string) {
        return instance.get<unknown, { data: ContainerDetailsType }>(`/ff/container_details/${id}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    setShipmentsTrackingId(cookies: Cookies, data: AddShipmentTrackingDataType) {
        return instance.post(`/ff/add_tracking/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    uploadContainerFile(cookies: Cookies, file: File, updateExisting: boolean) {
        const formData = new FormData();

        updateExisting && formData.append("update_existing", `${updateExisting}`);
        formData.append("file", file);

        return instance.post(`/ff/container_file_upload/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    createContainerTemplateFile(containerId: string, cookies: Cookies) {
        return instance.get<CreateFileAxiosDataType>(`/ff/container_template_file/${containerId}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const invoicePortalAPI = {
    getInvoiceList(tenantId: string, containerType: string, cookies: Cookies, items: number, offset: number, tabState: string) {
        return instance.get<unknown, GetInvoiceListAxiosRequestType>(`/ff/invoices_list/${tabState}/${containerType}/?limit=${items ? items : 20}&offset=${offset}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    createInvoiceFile(invoiceId: string, cookies: Cookies) {
        return instance.get<unknown, CreateFileAxiosRequestType>(`/ff/invoice_file/${invoiceId}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getInvoiceDetails(cookies: Cookies, id: string) {
        return instance.get<unknown, { data: InvoiceDetailType }>(`ff/invoice_details/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getInvoiceFiltersData(cookies: Cookies, type: string, tabState: string) {
        return instance.get<unknown, { data: InvoiceFiltersData[] }>(`ff/invoice_filters_data/${tabState}/${type}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postDownloadPortalBillingFile(cookies: Cookies, type: "freight" | "tariff", data: { start_date: string | Date; end_date: string | Date; ex_rate?: string }) {
        return instance.post<{ url: string }>(`ff/${type}_fee_invoice_file/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const detailPortalAPI = {
    getDetailList(
        type: string,
        cookies: Cookies,
        searchDetailFilter: string,
        sortDetailFilter: string,
        periodDetailFilter: PeriodDetailFilterType,
        containerFilter: string,
        items: number,
        offset: number,
        tabState: string
    ) {
        const { generatedStartDate, generatedEndDate } = generateRangeDate(periodDetailFilter);

        return instance.get<unknown, GetDetailsAxiosRequestType>(
            `/ff/shipment_details_list/${tabState}/${type}/?limit=${items}&offset=${offset}&${searchDetailFilter ? `search=${searchDetailFilter}` : ""}${sortDetailFilter ? `&ordering=${sortDetailFilter}` : ""}${generatedStartDate && generatedEndDate ? `&from_date=${generatedStartDate}&to_date=${generatedEndDate}` : ""}${containerFilter ? `&container=${containerFilter}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    createDetailFile(orders: string[], containerNumber: string, cookies: Cookies, tabState: string) {
        let requestData;

        if (containerNumber) {
            requestData = {
                id_list: orders,
                container_number: containerNumber,
                country_code: tabState,
            };
        } else {
            requestData = {
                id_list: orders,
                country_code: tabState,
            };
        }

        return instance.post<unknown, CreateFileAxiosRequestType>(`/ff/details_file/`, requestData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getShipmentsFiltersData(cookies: Cookies, type: string, searchDetailFilter: string, startDate: string, endDate: string, tabState: string) {
        return instance.get<unknown, { data: ShipmentsFilterDataType[] }>(
            `ff/shipment_details_filters_data/${tabState}/${type}/?${searchDetailFilter ? `&search=${window.encodeURIComponent(searchDetailFilter)}` : ""}${startDate && endDate ? `&from_date=${startDate}&to_date=${endDate}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};

export const airShippingPortalAPI = {
    getAirShippingList(
        cookies: Cookies,
        searchDetailFilter: string,
        sortDetailFilter: string,
        periodDetailFilter: PeriodDetailFilterType,
        items: number,
        offset: number,
        tabState: string
    ) {
        const { generatedStartDate, generatedEndDate } = generateRangeDate(periodDetailFilter);

        return instance.get<unknown, GetDetailsAxiosRequestType>(
            `/ff/shipment_details_list/${tabState}/${tabState === "US" ? "AIR_REGULAR" : "UK_TRUCK"}/?limit=${items}&offset=${offset}&${searchDetailFilter ? `search=${searchDetailFilter}` : ""}${sortDetailFilter ? `&ordering=${sortDetailFilter}` : ""}${generatedStartDate && generatedEndDate ? `&from_date=${generatedStartDate}&to_date=${generatedEndDate}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};

export const taxRulePortalAPI = {
    getTaxRuleList(cookies: Cookies, search: string, tabState: string) {
        return instance.get<unknown, GetTaxRuleAxiosRequestType>(`/ff/hs_codes_tariff_list/${tabState}/${search ? `?search=${window.encodeURIComponent(search)}` : ""}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getTaxRuleListAll(cookies: Cookies, items: number, offset: number, search: string) {
        return instance.get<unknown, GetTaxRuleAxiosRequestType>(
            `/ff/hs_codes_tariff_list/US/?limit=${items}&offset=${offset}${search !== "" ? `&search=${window.encodeURIComponent(search)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    updateTaxRule(id: string, data: TaxRuleUpdateType, cookies: Cookies) {
        return instance.patch(`/ff/update_hs_code_tariff/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const brokerApproveAPI = {
    getBrokerApproveList(cookies: Cookies, search: string) {
        return instance.get<unknown, { data: GetBrokerApproveListResponseType }>(
            `docusign/broker-approving/${search !== "" ? `?search=${window.encodeURIComponent(search)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getBrokerApproveListAll(cookies: Cookies, items: number, offset: number, search: string) {
        return instance.get<unknown, { data: GetBrokerApproveListResponseType }>(
            `docusign/broker-approving/?limit=${items}&offset=${offset}${search !== "" ? `&search=${window.encodeURIComponent(search)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    brokerDocumentApprove(cookies: Cookies, id: string, object: { approved_status: string }) {
        return instance.patch(`docusign/broker-approving/${id}/`, object, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
