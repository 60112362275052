export const TRANSLATIONS_EN = {
    userTotalStorage: "Total Storage",
    //Right Nav
    dashboard: "Dashboard",
    sendProductToSKUDrop: "Send Products to SKUdrop",
    sendProductToAmazon: "Send Products to Amazon",
    addProduct: "Add Products or Brands",
    createOutboundShipments: "Create Outbound Shipments",
    requestForFreightQuote: "Request for Freight Quote",
    //Main Totals
    totalUnitsStored: "Total Units Stored",
    totalCartonsStored: "Total Cartons Stored",
    totalPalletsStored: "Total Pallets Stored",
    cubicMetersStored: "Cubic Meters Stored",
    cubicFeetStored: "Cubic Feet Stored",
    //Main Incoming totals
    unitsIncoming: "units incoming",
    cartonsIncoming: "cartons incoming",
    palletsIncoming: "pallets incoming",
    cubicMetersIncoming: "cubic meters incoming",
    cubicFeetIncoming: "cubic feet incoming",
    // in Prep
    inPrep: "in prep",
    // Switch
    cubicMeters: "Cubic meters",
    cubicFeet: "Cubic feet",
    // Table Names
    storedProduct: "Stored products",
    productsPrepped: "Products being prepared",
    incomingProducts: "Incoming products",
    productsRequiringActions: "Products requiring actions",
    uploadedProducts: "Uploaded products",
    seeAll: "See all",
    allStoredProduct: "All stored products",
    allProductsPrepped: "All products being prepared",
    allIncomingProducts: "All incoming products",
    allProductsRequiringActions: "All products requiring actions",
    allUploadedProducts: "All uploaded products",
    searchFor: "Search for",
    typeInA: "Type in a",
    typeIn: "Type in",
    searchForNames: "Search for names...",
    searchForSKU: "Search for SKU...",
    searchForDate: "Search for date...",
    close: "Close",
    cancel: "Cancel",
    delete: "Delete",
    disable: "DISABLE",
    active: "ACTIVATE",
    confirmationRequired: "CONFIRMATION REQUIRED",
    save: "SAVE",
    confirmDeletion: "CONFIRM DELETION",
    addMultipleSKUsSeparatedByComma: "Add multiple SKUs separated by a comma",
    typeSKUsToShipThenPressEnter: "Type SKUs to ship then press enter...",
    typeSKUsThenPressEnter: "Type SKUs then press enter...",
    // Tables Header Title
    brandName: "Brand Name",
    productName: "Product Name",
    sku: "SKU",
    unitsPerCarton: "Units per carton",
    cartonWeight: "Carton Weight",
    cartonDimension: "Carton Dimension",
    numberOfUnits: "Number of Units",
    numberOfCartons: "Number of Cartons",
    numberOfPallets: "Number of Pallets",
    incomingId: "Incoming ID",
    action: "Action",
    orderNumber: "Order Number",
    amazonFBAID: "Amazon FBA ID",
    reason: "Reasons",
    listReason: "List Reasons",
    productDetails: "Product Details",
    masterCartonDimensions: "Master Carton Dimensions",
    pleaseUpdateTheNumberOfCartons: "Please update the number of cartons per pallet.",
    masterCartonOnPallet: "Master Cartons on a pallet",
    skog: "SKOG",
    destinationWarehouse: "Destination warehouse",
    // Send product to SKUdrop
    sendProductToSKUDropTitle: "Select carton quantities to send to SKUdrop",
    noPrepareToShipProduct: "Sorry, you don't have to prepare to ship products in SKUdrop!",
    masterCartons: "Master Cartons",
    unitPrice: "Unit Price",
    commercialInvoice: "Commercial Invoice",
    update: "UPDATE",
    upload: "UPLOAD",
    submit: "SUBMIT",
    estimateDateTitle: "Estimated date goods will arrive",
    sendProductToSKUDropModalTitle: "The SKUdrop team are ready and waiting for your goods to arrive.",
    sendProductToSKUDropModalDescription: "An email has been sent with our warehouse address details for carton delivery to SKUdrop.",
    factoryNameAlreadyTaken: "Factory name already taken",
    useExistingSavedSupplier: "Use existing saved supplier",
    orAddNewFactoryDetails: "OR ADD NEW FACTORY DETAILS",
    selectFactory: "Select factory",
    contactPersonName: "Contact person name",
    contactPersonPhoneNumber: "Contact person phone number",
    nicknameForThisProductsFactory: "Nickname for this product's factory",
    // Bulk Commercial Invoice
    bulkUpload: "Bulk Upload",
    bulkCommercialInvoiceUpload: "Bulk Commercial Invoice Upload",
    uploadCommercialInvoiceAndAssignMultipleSKUsToIt: "Upload a commercial invoice and assign multiple SKUs to it.",
    uploadInvoice: "Upload Invoice",
    uploadTheCommercialInvoiceToUse: "Upload the commercial invoice to use.",
    assignMultipleSKUsToUseTheUploadedInvoice: "Assign multiple SKUs to use the uploaded invoice.",
    saveInvoiceForTheseSKUs: "Save invoice for these SKUs",
    // Bulk SKU Inbound
    inboundSKUsInBulk: "INBOUND SKUS IN BULK",
    bulkSkuInbounding: "Bulk SKU Inbounding",
    followTheStepsBelowToBulkInboundSKUsToTheSKUdropWarehouse: "Follow the steps below to bulk inbound SKUs to the SKUdrop warehouse.",
    downloadTheBulkInboundTemplate: "Download the bulk inbound template.",
    fillInTheTemplateWithTheDetailsOfYourThatYouWantToSendToSKUdrop: "Fill in the template with the details of your SKUs that you want to send to SKUdrop.",
    // Send Product to Amazon
    noSendProductToAmazon: "Sorry you don't have products stored in SKUdrop!",
    sendProductToAmazonStepOneTitle: "Step 1 - Select Shipping Method and Number of Cartons",
    cartonsStored: "Cartons Stored",
    destination: "Destination",
    selectNumberOfCartons: "Select Number of Cartons",
    units: "units",
    comingSoon: "coming soon",
    createShipmentPlanButtonTitle: "CREATE SHIPPING PLAN WITHIN SELLER CENTRAL",
    shipmentID: "Shipment ID",
    shipTo: "Ship to",
    unitsExpected: "Unit expected",
    calculateShippingEstimateButtonTitle: "CALCULATE SHIPPING ESTIMATE",
    sendProductToAmazonStepTwoTitle: "Step {{step}} - Select Shipping Cost Option",
    submitShippingOrderButtonTitle: "CONFIRM SHIPPING PLAN, ESTIMATE AND SEND CARTON LABELS",
    sendProductToAmazonModalTitle: "Success! Your shipping plans and labels have been sent to SKUdrop",
    sendProductToAmazonModalNote: "NOTE: If you delete or change your shipping plan, you must contact",
    asap: "ASAP",
    goToDashboardButtonTitle: "GO TO DASHBOARD",
    consolidateShipments: "Consolidate shipments",
    displayShippingEstimate: "Display shipping estimate",
    reduceNumberOfShippingPlans: "Select this button to reduce the number of shipping plans",
    mostCostEffectivePlans: "MOST COST EFFECTIVE PLANS",
    leastCostEffectivePlans: "LEAST COST EFFECTIVE PLANS",
    reducesShippingCostsBy: "Reduces shipping costs by",
    increasesShippingCostsBy: "Increases shipping costs by",
    stepSelectDestinationAndShippingMethodOrEnterFbaIDs: "Step 1 - Select Destination and Shipping Method or Enter FBA IDs to Create Shipments",
    stepSelectShippingOptions: "Step 1 - Select Shipping Options",
    stepSelectDestinationAndShippingMethod: "Step 1 - Select Destination and Shipping Method",
    stepEnterFbaOrAwdIDsCsvFileAndLabelsToCreateShipmentsFromSellerCentral: "Step {{step}} - Enter {{type}} IDs, CSV file and Labels to Create Shipments from Seller Central",
    stepRetrievingAwdShippingInformation: "Step {{step}} - Retrieving AWD Shipping Information",
    stepAddFreightForwarderDetails: "Step {{step}} - Add Freight Forwarder Details",
    stepConfirmPrepFees: "Step {{step}} - Confirm Prep Fees",
    stepSelectNumberOfCartons: "Step {{step}} - Select Number of Cartons",
    stepSelectNumberOfCartonsToBuildContainer: "Step 2 - Select Number of Cartons to Build your Container",
    stepUploadLabelToPrep: "Step 3 - Upload Label to Prep",
    stepChooseContainerSize: "Step 1 - Choose your Container Size",
    confirmPrepFeesSubmitShipment: "CONFIRM PREP FEES & SUBMIT SHIPMENT",
    clear: "Clear",
    shipToAmazon: "SHIP TO AMAZON",
    enterFbaID: "Enter FBA ID",
    enterStarID: "Enter STAR ID",
    labelUploadedWillBeAppliedToAllCartons: "The label uploaded will be applied to all cartons in the shipment",
    noLabelRequiredForPrep: "No label required for prep",
    uploadShippingAddressLabels: "UPLOAD SHIPPING ADDRESS LABELS",
    selectFreightForwarderDetails: "Select Freight Forwarder Details",
    youCanSelectAndUpdateTheSavedFreightForwarderDetails: "You can select and update the saved Freight Forwarder details",
    logisticsCompanyName: "Logistics Company Name",
    containerSize: "Container Size",
    remainingCbmAvailableToLoad: "Remaining CBM available to load",
    remainingWeightAvailableToLoad: "Remaining weight available to load",
    ownFreightForwarder: "Own Freight Forwarder",
    pickCartons: "Pick cartons",
    retrieveFromRacks: "Retrieve from racks",
    prepCartons: "Prep cartons",
    labelCartons: "Label cartons",
    loadIntoContainer: "Load into container",
    loadYourSKUsIntoContainer: "Load your SKU's into container",
    loadCartons: "Load cartons",
    loadCartonsForTransport: "Load cartons for transport",
    welcomeToYour: "Welcome to your",
    containerBuilder: "Container Builder",
    selectCartonQuantitiesToBuildYourContainerBasedOnTheRequiredCBM: "Select carton quantities to build your container based on the required CBM.",
    watchHelpVideo: "Watch help video",
    stepChooseYourContainerSize: "Step 1 - Choose your Container Size",
    createFlatFile: "Create Flat File",
    flatFileCreated: "FLAT FILE CREATED",
    uploadFlatFileInSkuSelectionMethodInSellerCentralToCreateThisShipment: "Upload the flat file in the SKU selection method in Seller Central to create this shipment.",
    onceYourShipmentIsReadyInSellerCentralEnterYour: "Once your shipment is ready in Seller Central, enter your",
    fbaIDsOnThisPageToSendThisShipmentToWarehouse: "FBA IDs on this page to send this shipment to the warehouse.",
    dontShowThisAgain: "Don’t show this again",
    weWereUnableToReceiveYourOrderOrLabels: "We were unable to receive your order or labels. Please upload your FBA labels.",
    orderReceived: "Order received",
    labelsReceived: "Labels received",
    labelUpload: "Label upload",
    clickHere: "CLICK HERE",
    uploadLabels: "Upload labels",
    shipWithSKUdrop: "Ship with SKUdrop",
    done: "Done",
    aglHelpVideo: "AGL help video",
    uploadAwdLabels: "UPLOAD AWD LABELS",
    noCartonsReplenishYourSKUdropInventory: "There are no cartons stored to create an outbound shipment via Amazon's API.",
    ifYouHaveGlobalSKUsEnabledSelect: "If you have global SKUs enabled, select",
    belowAndSelectSKUsFromAnyIntegrationToSendCartonsToThisMarketplace: "below and select SKUs from any integration to send cartons to this marketplace.",
    noCartonsToCreateOutboundShipment: "There are no cartons to create an outbound shipment.",
    stepSelectPlacementOption: "Step {{value}} - Select Placement Option",
    stepReviewAndConfirmShipmentPlan: "Step {{value}} - Review and Confirm Shipment Plan",
    deletePlacements: "Delete placements",
    deletePlan: "Delete plan",
    mostCostEffectiveOption: "Most cost effective option",
    mostCostEffectiveOptionWhenComparingAllShipmentSplits: "Most cost effective option when comparing all shipment splits",
    amazonOptimizedShipmentSplits: "Amazon optimized shipment splits",
    partialShipmentSplits: "Partial shipment splits",
    minimalShipmentSplits: "Minimal shipment splits",
    shipmentsLoverCase: "shipments",
    shipmentLoverCase: "shipment",
    lessDetails: "Less details",
    weWillSendInventoryToMultipleLocations: "We will send inventory to multiple locations.",
    weWillSendInventoryToFewerLocations: "We will send inventory to fewer locations. Amazon will spread the inventory for you and charge a fee per unit.",
    weWillSendInventoryToOneLocation: "We will send inventory to one location. Amazon will spread the inventory for you and charge a fee per unit.",
    noPlacementFeePayable: "No placement fee payable",
    startingAt: "Starting at",
    inboundRegion: "Inbound region",
    ok: "OK",
    toSwitchToAirFreightPricing: "To switch to Air Freight pricing\nexisting plans need to be deleted",
    yourAccountIsSuspended: "Your account is suspended.",
    enterTheNumberOfCartonsForAtLeastOneProduct: "Enter the number of cartons for at least 1 product",
    tenantOwnerNeedsToSignPOADocument: "Tenant owner needs to sign a POA document",
    west: "West",
    east: "East",
    mid: "Mid",
    optimized: "Optimized",
    unavailable: "Unavailable",
    multipleSuppliersDetected: "MULTIPLE SUPPLIERS DETECTED",
    toStreamlineOurShippingAndExportDocumentationProcesses:
        "To streamline our shipping and export documentation processes, we require that multiple SKU shipments be grouped by the same supplier when creating shipping plans.",
    thisEnsuresYourShipmentsAreHandledEfficiently: "This ensures your shipments are handled efficiently and delivered to the appropriate fulfillment center in a timely manner.",
    iUnderstand: "I understand",
    addShipment: "Add Shipment",
    csvFileCapitalize: "CSV File",
    labelsFileCapitalize: "Labels File",
    uploaded: "Uploaded",
    HowToGetTheCsvFile: "How to get the CSV file",
    goToTheManageShipmentsPage: "Go to the manage shipments page",
    clickTheShipmentNameWhichOpensTheShipmentSummary: "Click the shipment name - which opens the shipment summary",
    clickTheShipmentNameInTheTopLeftHandCorner: "Click the shipment name in the top left hand corner",
    clickViewInStep3BoxLabelsPrinted: 'Click <strong>"View"</strong> in step 3 - Box labels printed',
    clickTheViewOrEditContentsLinkInTheShipmentBox: 'Click the <strong>"View or edit contents"</strong> link in the shipment box',
    clickThePrintPackListButton: 'Click the <strong>"Print pack list.csv"</strong> button',
    // Own ff announcement modal
    ownFFAnnouncementModalTitle1: `Introducing Custom Shipping Options:\nUse Your Own Freight Forwarder`,
    ownFFAnnouncementModalTitle2: `Take Advantage of Storing in China and\nShip Your Cartons Direct to Amazon`,
    ownFFAnnouncementModalTitle3: `Harness the Power of B2B\nShipments Across the Globe`,
    ownFFAnnouncementModalDesc1: "Now you can have full flexibility and control of your shipping requirements to best suit your needs.",
    ownFFAnnouncementModalDesc2: "Ship to Amazon and create an LCL or FCL shipment. Use AGL’s shipping options to eliminate inventory placement fees.",
    ownFFAnnouncementModalDesc3: "Ship your cartons to off-Amazon destinations anywhere in the world with ease.",
    // Own ff AGF modal
    importantInformation: "Important information",
    whenDoingAglShipments: "When doing AGL shipments",
    whenCreatingAGLShipmentsInSellerCentral:
        "When creating AGL shipments in seller\ncentral, there are some very important\nsettings that need to be set to ensure a\nsmooth shipment.",
    ownFFAglClickNextBelow: "Click <strong>NEXT</strong> below, to go through the settings.",
    setting: "Setting {{value}}",
    originCityShouldBeSetToNingbo: "Origin city should be set to Ningbo.",
    valueAddedServicesShouldBeSetToSupplierDropsOff: "Value-added services should be set to supplier drops off.",
    SKUdropWillDoTheDropOffToThePort: "SKUdrop will do the drop off to the port on\nyour behalf. Delivery charges apply.",
    shipperShouldBeSetToYourSuppliersAddress: "Shipper should be set to your suppliers address,\ndestination contact should be your contact details.",
    youMustDoOneAglShipmentPerSupplier: "You must do one AGL shipment per supplier\nto simplify the export paperwork.",
    selectDestinationContactForPrimaryContact: "Select destination contact for primary contact.",
    thisMeansThatAglWillIncludeYouInAll: "This means that AGL will include you in all\ncommunications for this shipment.",
    yourRoleWithAglShipments: "Your role with AGL shipments",
    youPlayAnImportantRoleInAglShipments: "You play an important role in AGL shipments.",
    inOrderForSKUdropToDeliverYourGoods: "In order for SKUdrop to deliver your goods, we\nneed specific paper work from your supplier\nto coordinate the port drop off for you.",
    weMayNeedYouToChaseUpTheRelevant: "We may need you to chase up the relevant\npaperwork in order for us to send your\nshipment to the port.",
    toLearnMoreAboutOurPortDeliveryRates:
        "To learn more about our port delivery rates\nand to see an example of the delivery\ndocument we need from your supplier, click\nthe download button below.",
    // Add products or brand
    importFromAmazonButtonTitle: "IMPORT PRODUCTS FROM AMAZON",
    addManualyButtonTitle: "MANUALLY ADD PRODUCT DETAILS",
    addProductFromAmazonTitle: "Select products from Seller Central to add to SKUdrop",
    hsCode: "HS code",
    name: "Name",
    asin: "ASIN",
    selectAll: "Select All",
    carton: "Carton",
    cartons: "Cartons",
    pallet: "Pallet",
    select: "Select",
    updateOrImportProductFromAmazon: "Update or Import Products From Amazon",
    importNewProducts: "IMPORT NEW PRODUCTS",
    updatedAllProducts: "UPDATE ALL PRODUCTS",
    importingHasBeenStarted: "Importing new products has been started!",
    updatingHasBeenStarted: "Updating Amazon products has been started",
    addProductFromAmazonButtonTitle: "ADD SELECTED PRODUCTS TO SKUdrop",
    updateAmazonProductButtonTitle: "UPDATE AMAZON PRODUCT",
    newBrandNamePlaceholder: "New Brand Name or Select Existing Brand Name from Dropdown",
    manualyProductDetailsTitle: "Product Details for Storage at SKUdrop",
    addProductTooltipTitle: "If you're adding a variation, master carton details can be copied from an existing product on the next screen.",
    next: "NEXT",
    letMeRecheckButtonTitle: "LET ME RECHECK",
    beforeWeSave: "Before we save...",
    addVariationModalDescription: "Have you adjusted the master cartons details for this variation so we have the correct product details?",
    masterCartonDetails: "Master Cartons Details",
    selectProductToCopy: "Select Product to Copy it's Carton Details",
    inch: "INCHES",
    cm: "CM'S",
    height: "Height",
    width: "Width",
    length: "Length",
    masterCartonWeight: "Master Carton Weight",
    kg: "KG's",
    pounds: "Pounds",
    masterCartonPerPallet: "Master Cartons per pallet",
    masterCartonPerPalletOptional: "Master Cartons per pallet (optional)",
    basedOnAmazonPallet: "Based on Amazon pallet thresholds.",
    variationName: "Variation Name",
    variationASIN: "Variation ASIN",
    variationSKU: "Variation SKU",
    variationHSCode: "Variation HS code",
    variationUnitPrice: "Variation Unit Price",
    adjustMasterCartonDetails: "Adjust master carton details above for variation before saving",
    addAnotherProductButtonTitle: "ADD ANOTHER PRODUCT OR BRAND",
    saveAndCopyButtonTitle: "SAVE AND COPY TO ADD VARIATION",
    saveAndGoButtonTitle: "SAVE AND GO TO DASHBOARD",
    productCategory: "Product category",
    productDocuments: "Product documents",
    waitingDocuments: "Waiting for documents",
    documents: "Documents",
    downloadDocumentsUpper: "DOWNLOAD DOCUMENTS",
    only3DecimalPlacesAreAllowed: "Only 3 decimal places are allowed",
    // Settings
    profile: "Profile",
    billing: "Billing",
    allUserBilling: "All User Billing",
    pastShipments: "Past Shipments",
    integrations: "Integrations",
    logOut: "Log-out",
    // Profile
    userDetails: "User Details",
    lastName: "Last Name",
    email: "Email",
    billingAdress: "Billing Address (optional)",
    line1: "Line 1",
    postalCode: "Postal Code",
    city: "City",
    state: "State",
    country_code: "Country Code",
    phone: "Enter your phone",
    changePassword: "Change Password",
    currentPassword: "Current password",
    newPassword: "New password",
    confirmPassword: "Confirm Password",
    confirmSmal: "Confirm", //Added
    creditCard: "Credit Card",
    userManagement: "User Management",
    addUser: "ADD USER",
    userEmail: "User email",
    userAlredyExist: "User with this email already exists",
    fullName: "Full Name",
    allUsers: "All Users",
    billingAccess: "Billing access",
    bankTransfer: "Bank Transfer",
    // Public API
    publicApiKeys: "Public API Keys",
    publicApiDescription:
        "Generate your public API key in your profile to seamlessly integrate SKUdrop's features with your existing systems. Unlock efficient, automated interactions for enhanced business operations.",
    keyName: "Key Name",
    lastUsed: "Last used",
    extendExpiration: "Extend Expiration",
    extendExpirationDateBy: "Extend the expiration date by",
    expirationDate: "Expiration date",
    generateApiKey: "Generate Public API Key",
    createApiKey: "Create API Key",
    apiKeyCreated: "API Key Created",
    unlimited: "Unlimited",
    extend: "Extend",
    copy: "Copy",
    copied: "Copied",
    yourAPIKeyIsDisplayedOnlyOnce:
        "Your API key is displayed only once for security reasons. Please copy and store it securely immediately, as you will not be able to retrieve it later",
    day: "day",
    days: "days",
    weeks: "weeks",
    month: "month",
    // Billing
    noBillingData: "You have no billing data.", //changed
    invoiceDate: "Invoice Date",
    status: "Status",
    total: "Total",
    openDownload: "Invoice",
    currentSkudropPricingTier: "Your current SKUdrop pricing tier and shipping credit balance",
    cartonPreparationFee: "Carton preparation fee",
    dailyCBMStorageRate: "Daily CBM storage rate",
    shippingCreditsBalance: "Shipping credits balance",
    cbmStorageRange: "CBM storage range",
    shippingCreditsUsed: "Shipping credits used",
    currentShippingCreditBalance: "Current shipping credit balance",
    // Payment terms
    paymentsDue: "Payments due",
    paymentsOverdue: "Payments overdue",
    amountPayable: "Amount payable",
    dueDate: "Due date",
    paymentMethod: "Payment method",
    confirmPayment: "Confirm payment",
    verifyTransactionAmountAndConfirmPayment: "Please verify the transaction amount and confirm the payment.",
    forYourBankTransferPleasePayAsPerInvoice: "For your bank transfer please pay as per invoice due date and cover all payment fees.",
    creditCardTransactionIncludesProcessingFee: "The credit card transaction includes a processing fee.",
    processingFee: "Processing fee",
    transactionAmount: "Transaction amount",
    totalTransactionAmount: "Total transaction amount",
    // Past Shipments
    shippedToAmazon: "Shipped To Amazon",
    shippedToSKUDrop: "Shipped To SKUdrop",
    noShippmentsToAmazon: "There are no cartons to ship to Amazon.", //changed
    noShippmentsToSKUDrop: "You haven't shipped any cartons to SKUdrop.",
    orderId: "Order ID",
    shipDate: "Ship Date",
    sentTo: "Sent to",
    track: "Track",
    cost: "Cost",
    allShippedToAmazon: "All shipped to Amazon products",
    allShippedToSKUDrop: "All shipped to SKUdrop products",
    receivedDate: "Received Date",
    inboundOrders: "Inbound orders",
    totalCBM: "Total CBM",
    totalCost: "Total Cost",
    totalPrepCost: "Total prep cost",
    allPastInboundOrders: "All Past Inbound Orders",
    allPastSKUdropOrders: "All Past SKUdrop Orders",
    allPastOwnFFOrders: "All Past Own FF Orders",
    allPastQuoteOrders: "All Past Quote Orders",
    thereAreNoPastShipments: "There are no past shipments.",
    // Dashboard: Outgoing Orders
    allSKUdropProductsBeingPrepared: "All SKUdrop products being prepared",
    allOwnFFProductsBeingPrepared: "All Own FF products being prepared",
    allQuoteProductsBeingPrepared: "All Quote products being prepared",
    totalNumberOfCartonsCapitalize: "Total Number of Cartons",
    totalNumberOfUnitsCapitalize: "Total Number of Units",
    totalCubicMeters: "Total cubic meters",
    totalCubicFeet: "Total cubic feet",
    // Integration
    integrationName: "Integration Name",
    authorizationDate: "Authorization Date",
    loginWithAmazon: "Login With Amazon",
    noIntegrations: "There are no integrations.",
    // ADMIN WAREHOUSE
    searchPastOrders: "Search past orders",
    warehouseDashboard: "Warehouse Dashboard",
    chargingRates: "Charging rates",
    addNewWarehouse: "Add new Warehouse",
    tenantList: "Tenant list",
    // Warehouse Dashboard
    orderToPrep: "Orders to prep",
    orderToShip: "Orders to ship",
    incomingCartons: "Incoming cartons",
    productList: "Stored Products",
    trackingIDEntry: "Tracking ID entry",
    tariffManagement: "Tariff Management",
    tariffManagementTitle: "Manage tariffs and taxes",
    allOrderToPrep: "All orders to prep",
    allOrderToShip: "All orders to ship",
    allProductList: "All stored products",
    allTrackingIDEntry: "All tracking ID entry",
    allTariffManagement: "All tariffs",
    noOrderToPrep: "There are no orders to prep.",
    noOrderToShip: "There are no orders to ship.",
    noIncomingCartons: "There are no incoming cartons.",
    noProductList: "There are no products stored.",
    noTrackingIDEntry: "There are no shipments requiring tracking ID's to be entered.",
    noTariffManagement: "There are no tariffs required to be paid.",
    waiting: "Waiting",
    fbaID: "FBA ID",
    timeReceived: "Time received",
    files: "Files",
    completed: "Completed",
    yes: "Yes",
    no: "No",
    haveYouAddedFBALabels: "Have you added FBA labels for",
    howManyCartonsAreAvailable: "How many cartons are available?",
    adviceCustomer: "ADVISE CUSTOMER",
    fileDownloads: "FILE DOWNLOADS",
    download: "DOWNLOAD",
    fbaShippingLabel: "FBA shipping label",
    shippingTemplate: "Shipping template",
    shippingToFBATemplate: "Shipping to FBA template",
    pickedUp: "Picked up",
    loaded: "Loaded",
    finalWeight: "Final weight",
    cartonPerPallet: "Cartons per pallet",
    estDeliveryTime: "Est. delivery",
    haveYouReceivedCartons: "Confirm cartons received",
    weight: "Weight",
    productDetailInformation: "Product detail information",
    warehouseReceivedInformation: "Warehouse receiving information",
    downloadAll: "DOWNLOAD ALL",
    dateShipped: "Date Shipped",
    addTrackingID: "Add Tracking ID",
    trackingID: "Tracking ID",
    confirmTrackingID: "Confirm Tracking ID",
    enterTrackingIDBelow: "Enter tracking ID/s below",
    editTrackingIDsBelow: "Edit tracking ID/s below",
    editTrackingID: "Edit Tracking ID",
    enterTrackingID: "Enter tracking ID",
    invalidTrackingID: "Invalid Tracking ID",
    confirm: "CONFIRM",
    charge: "CHARGE",
    chargeTariff: "Charge Tariff",
    youAreAboutToCharge: "YOU ARE ABOUT TO CHARGE",
    forImportTariffs: "FOR IMPORT TARIFFS AND TAXES DUE",
    chargeCardOnFile: "CHARGE CARD ON FILE",
    chargeTariffAmountFor: "CHARGE TARIFF AMOUNT FOR",
    totalAmountPayable: "TOTAL AMOUNT PAYABLE",
    haveYouDoubleChecked: "Have you double checked the tariff amount is for the above FBA ID?",
    tryAgainUpper: "TRY AGAIN",
    uploadTariffFile: "upload tariff file",
    packingListFile: "Packing list file",
    tariffFile: "Tariff File",
    warehouseLabels: "Warehouse labels",
    totals: "Totals",
    downloadAllData: "Download all data",
    orderHaveZeroChargeAmount: "This order has a $0.00 tariff amount, are you sure you want to continue?",
    ordersHaveZeroChargeAmount: "These orders have a $0.00 tariff amount, are you sure you want to continue?",
    finalCBM: "Final CBM",
    confirmFinalWeight: "Confirm final weight",
    confirmFinalCBM: "Confirm final CBM",
    confirmFinalWeightAndCbm: "Confirm final weight and CBM",
    maximumDecimalPlaces: "Maximum {{value}} decimal places",
    uploadProof: "Upload proof",
    pickUpTime: "Pick up time",
    chooseTime: "Choose time",
    selectPickUpTime: "Select pick up time",
    uploadProofForOrder: "Upload proof for order",
    uploadedProofForOrder: "Uploaded proof for order",
    alreadyUploadedProof: "Already uploaded proof",
    newUploadedProof: "New uploaded proof",
    uploadedProof: "Uploaded Proof",
    view: "View",
    moreDetails: "More details",
    country: "Country",
    orderDetails: "Order details",
    success: "Success",
    error: "Error",
    processing: "Processing",
    skudropOrders: "SKUdrop orders",
    ffOrders: "FF orders",
    fileDownloadsCapitalize: "File Downloads",
    shipmentLabels: "Shipment Labels",
    shippingLabelsCapitalize: "Shipping Labels",
    alreadyUploadedProofsWillBeDeleted: "Already uploaded proofs will be deleted",
    hasThisShipmentBeenPickedUp: "Has this shipment been picked up?",
    hasThisShipmentBeenLoaded: "Has this shipment been loaded?",
    haveTheseShipmentsBeenPickedUp: "Have these shipments been picked up?",
    hasThisOrderBeenPrepared: "Has this order been prepared?",
    orderTemplate: "Order template",
    prepCostCapitalize: "Prep Cost",
    relatedSku: "Related SKU",
    bulkPickedUp: "Bulk Picked up",
    bulkLoaded: "Bulk Loaded",
    exampleShort: "Ex.",
    supplierDetails: "Supplier details",
    completedBulkActions: "COMPLETED BULK ACTIONS",
    enterPriceHere: "Enter price here",
    selectContainer: "Select container",
    noContainersAvailable: "No containers available",
    totalTariffCapitalize: "Total Tariff",
    manage: "Manage",
    orderTariffManagement: "Order tariff management",
    totalOrderTariff: "Total order tariff",
    chargeOrder: "Charge order",
    youAreTryingToSaveZeroTariffForOrder: "You are trying to save $0.00 tariff for {{order}} order. Are you sure you want to continue?",
    noSuppliers: "No suppliers",
    totalAmountPaidWithValue: "Total amount paid: ${{value}}",
    totalAmountCanNotBeLessThanAmountForPaidOrders: "The total amount can not be less than the tariff amount paid for previous orders.",
    totalAmountIsEqualToPaidAmountUnpaidOrdersWillReceiveZeroAmount: "The total amount is equal to the previously paid tariff amount. Unpaid orders will receive $0.00 amount.",
    thePaidOrdersAre: "The paid orders are",
    paidOrderForAmount: "Paid order for ${{value}}",
    selectDeliveryWindow: "Select delivery window",
    thereAreNoAvailableDeliveryWindows: "There are no available delivery windows",
    deliveryWindowCamelCase: "Delivery Window",
    pleaseSelectDeliveryWindowAccordingToExpectedDeliveryDate: "Please select the delivery window according to the expected delivery date",
    // Orders to ship: Bulk management
    bulkActions: "Bulk actions",
    selectBulkActionBellow: "SELECT BULK ACTION BELOW",
    selectUpToOrdersToBulkPickUpOrLoadToSaveTime: "Select up to {{value}} orders to bulk pick up or load to save time.",
    typeOrderIdFbaIdThenPressEnter: "Type Order ID, FBA ID then press enter",
    pickedUpBulkActions: "PICKED UP BULK ACTIONS",
    loadedBulkActions: "LOADED BULK ACTIONS",
    allOrders: "All orders",
    allOrdersWithValue: "All {{value}} orders",
    noOrdersAvailable: "No orders available",
    verificationFile: "VERIFICATION FILE",
    // charging rates
    shippingRates: "Shipping rates",
    rates: "Rates",
    prepRate: "Prep rate",
    enterPrepRate: "Enter prep rate here",
    rmbRate: "RMB kg rate",
    enterRmbRate: "Enter RMB KG rate here",
    skudropMargin: "SKUdrop margin",
    enterSkudropMargin: "Enter skudrop margin",
    surchargeForCategories: "Surcharge for specific categories",
    enterSurchargeHere: "Enter surcharge here",
    minimum_cbm: "Minimum CBM",
    miminum_weight: "Minimum carton weight",
    enter_miminum_cbm: "Enter minimum CBM",
    enter_miminum_weight: "Enter minimum weight",
    rmbConversionRate: "RMB/USD conversion rate",
    enterRmbConversionRate: "Enter RMB to USD conversion rate here",
    rmbKgRate: "SKUdrop RMB kg rate",
    enterRmbKgRate: "Enter RMB KG rate here",
    over266Rate: "Over 266cm rate",
    enterOver266Rate: "Enter over 266cm rate in RMB here",
    CartonRate22_40: "22kg - 40kg carton rate",
    enterCartonRate22_40: "Enter 22kg - 40kg rate here",
    LWH_75_120: "L or W or H = 75cm - 120cm",
    enter_LWH_75_120: "Enter L/W/H = 75cm - 120cm rate here",
    LWH_243: "L or W or H > 243cm",
    enter_LWH_243: "Enter L/W/H > 243cm rate here",
    between_330_419: "Between 330cm - 419cm",
    enter_between_330_419: "Enter between 330cm - 419cm rate here",
    slowBoat: "Slow boat",
    mediumBoat: "Medium boat",
    fastBoat: "Fast boat",
    savesCharges: "SAVE CHARGES",
    ratesFileUpload: "Rates file upload for US and UK pricing",
    mainRates: "Main rates",
    dailyStorageRatePerCBM: "Daily storage rate per CBM",
    enterDailyStorageRatePerCBM: "Enter daily storage rate per CBM",
    skudropAdditionalRates: "SKUdrop additional rates",
    ownFFPrepRateOver50CBM: "Prep rate for Own FF and Quote orders over 50 CBM",
    // Customer rates
    customerRates: "Customer rates",
    selectCustomer: "Select customer",
    customersLower: "customers",
    defaultRates: "Default rates",
    customRates: "Custom rates",
    marginForShipping: "Margin for shipping",
    prepFeeDiscountLower: "Prep fee discount",
    dailyStorageCBMRate: "Daily storage CBM rate",
    saveCustomRates: "Save custom rates",
    confirmCustomerRates: "Confirm Customer Rates",
    ratesType: "Rates type",
    reviewNewRatesAndConfirm: "Review the new customer rates and confirm the changes",
    addShippingCredits: "Add shipping credits",
    addCredits: "Add credits",
    consolidationChangeConfirmation: "This change will affect the functionality of the platform.\nAre you sure you want to continue?",
    activateOver141CBM: "These rates will activate when the customer has over 141 CBM",
    monthlySubscription: "Monthly subscription",
    monthlySubscriptionIsActivated: "The free monthly subscription will be activated",
    monthlySubscriptionIsDisabled: "The free monthly subscription will be cancelled",
    disableMonthlySubscription: "Disable monthly subscription",
    enabled: "Enabled",
    disabled: "Disabled",
    activateGlobalSKUs: "Activate global SKUs",
    shipmentsConsolidationControl: "Shipments consolidation control",
    disableCamelCase: "Disable",
    enableCamelCase: "Enable",
    allowedQuoteMarketplaces: "Allowed quote marketplaces",
    selectMarketplaces: "Select marketplaces",
    noMarketplaces: "No marketplaces",
    noMarketplacesLoverCase: "no marketplaces",
    confirmCustomerMarketplaces: "Confirm Customer Marketplaces",
    reviewNewQuoteMarketplacesForCustomerAndConfirmChanges: "Review new quote marketplaces for the customer and confirm changes",
    TheseChangesWillBeActiveWhenManualQuotingIsActivatedForCustomer: "These changes will be active when manual quoting is activated for the customer",
    prepRateOver50CBM: "Prep rate over 50 CBM",
    // Payment Terms
    paymentTerms: "Payment terms",
    paymentWindow: "Payment window",
    saleOnTimeDiscount: "1% on time discount",
    noOfDaysToPay: "No. of days to pay",
    NoOfCalendarMonths: "No. of calendar months",
    // Customer tiers
    customerTiers: "Customer tiers",
    tierLevel: "Tier {{value}}",
    defaultTiers: "Default tiers",
    customTiers: "Custom tiers",
    tierStorageCost: "Tier storage cost",
    tierPrepCost: "Tier prep cost",
    saveCustomTierStorageRates: "SAVE CUSTOM TIER STORAGE RATES",
    confirmCustomerTiers: "Confirm Customer Tiers",
    tiersType: "Tiers type",
    cbmRange: "CBM range",
    prepCost: "Prep cost",
    storageFee: "Storage fee",
    reviewTheNewCustomerTiersAndConfirmTheChanges: "Review the new customer tiers and confirm the changes",
    newTiersWillBeAppliedOnlyAfterSwitchingToCustomTiers: "New tiers will be applied only after switching to custom tiers",
    // Add new Warehouse
    warehouseSetUp: "Warehouse set up",
    warehouseTooltip: "The Username allows you to personalize the SKUdrop link for your account.",
    warehouseID: "Warehouse ID",
    SKUDropUsername: "SKUdrop Username",
    adress: "Address",
    adressLine1: "Address line 1",
    adressLine2: "Address line 2",
    onChinese: "in Chinese",
    province: "Province",
    storageSpaceAvailable: "Storage Space Available",
    usableFloor: "Usable floor space in cubic meters",
    palletsCanBeStored: "Pallets can be stored",
    saveWarehouseDetails: "SAVE WAREHOUSE DETAILS",
    marketplace_id: "Marketplace ID",
    // Tenant list
    warehouseList: "Warehouse List",
    allWarehouseList: "All warehouses",
    totalStorageSbm: "Total storage CBM",
    palletsStored: "Pallets stored",
    palletSpaceAvailable: "Pallet space available",
    updateWarehouse: "Update",
    searchForWarehouse: "Search for Warehouse ID...",
    disableWarehouse: "Disable warehouse",
    edit: "EDIT",
    visit: "VISIT",
    warehouseDetails: "Warehouse Details",
    warehouseSpace: "Warehouse Space",
    avaibleStorage: "Available Storage",
    availablePallets: "Available Pallets",
    deleteWarehouse: "DELETE WAREHOUSE",
    userList: "Tenant List",
    totalCartons: "Total Cartons",
    storageCbm: "Storage CBM",
    totalPallets: "Total Pallets",
    incomingShipments: "Incoming shipments",
    outgoingShipments: "Outgoing shipments",
    deleteUser: "DELETE USER",
    noProductStored: "No products are stored in our system.", //added
    confirmSave: "CONFIRM SAVE", //added
    incomingCbm: "Incoming CBM",
    phoneNumberCapitalize: "Phone Number",
    editAddress: "Edit address",
    //Filter
    thisWeek: "This week",
    lastWeek: "Last week",
    thisMonth: "This month",
    thisYear: "This year",
    roles: "Roles",
    role: "Role",
    usersRole: "Users Roles",
    changeRole: "Change Role",
    user: "User",
    administrator: "Administrator",
    manager: "Manager",
    manageRole: "Manage roles", //added
    asignRole: "Assign Role", //added
    noUsers: "There are no users.",
    filterResults: "Filter results",
    cubicMetersAvailable: "Cubic meters available",
    shippingService: "Shipping service",
    lastMile: "Last mile",
    cargoDetails: "Cargo details",
    termsConditions: "Terms and conditions",
    fbaWarehouse: "FBA warehouse",
    emptyProductList: "IT'S TIME TO ADD SOME PRODUCTS!",
    emptyProductListDesc: "Before we help make your supply chain more efficient, we need your brand and product information. Click the button below to get started.",
    addBrandButtonTitle: "ADD BRANDS AND PRODUCTS",
    paymentType: "Payment type",
    valueMustBeNumber: "Value must be a number", //added
    heightValidationErrorMessage: "height must be less or equal 419 cm", //added
    heightRequared: "height required", //added
    widthValidationErrorMessage: "width must be less or equal 419 cm", //added
    widthRequared: "width required", //added
    lengthValidationErrorMessage: "length must be less or equal 419 cm", //added
    lengthRequared: "length required", //added
    weightValidation: "weight must be less or equal 68 kg or 149.91 pounds", //added
    fieldRequired: "This field is required",
    minMessage: "The number must be greater",
    maxMessage: "Must be lower than",
    cartonsInPrep: "Cartons in prep",
    cartonsInTransit: "cartons in transit",
    beforeAddTitle: "Import products from Amazon",
    importAllSkuFromSC: "Import all SKU's from Seller Central",
    importAllSku: "IMPORT ALL SKU'S",
    enterSpecificSku: "Enter specific SKU's to import from Seller Central",
    importSku: "IMPORT SKU'S",
    addIntegration: "Add integration",
    addIntegrationDesc: "Your account must have an active Amazon integration.",
    integration: "Integration",
    productsImporting: "We are importing your products from Seller Central. This can take a few minutes. We will email you when we have imported all of your SKU's.",
    updateExistingProduct: "Update existing products",
    updateProducts: "UPDATE PRODUCTS",
    // Shipping declaration
    shippingDeclarations: "SHIPPING DECLARATION",
    shippingDeclarationsDesc: "We are unable to ship products that contain the following:",
    powder: "Powder",
    liquids: "Liquids/oils",
    externalProducts: "Products with external batteries",
    shippingDeclarationsSecondary: "If the below SKU's contain any of the above materials, please remove them from the import list below.",
    remove: "REMOVE",
    backUpper: "BACK",
    explosives: "Explosives",
    flammableGases: "Flammable gases",
    flammableLiquids: "Flammable liquids/liquids",
    flammableProducts: "Flammable products",
    toxicSubstances: "Toxic substances",
    corrosiveProducts: "Corrosive products",
    counterfeitGoods: "Counterfeit goods",
    currencies: "Currencies",
    candles: "Candles",
    medicalEquipment: "Medical equipment",
    food: "Food",
    doesSkusFall: "Does any of your SKU's fall within the following categories?",
    skusContainAnyOfTheAbove: "If the SKU's below fall within one of the above categories, please select the appropriate category for each SKU.",
    notApplicable: "NOT APPLICABLE",
    weRequireTheFollowingDocuments: "In order to ship this product, we require documentation. For this category, we require the following documents:",
    manufacturerOrPrivateLabelerName: "Manufacturer or private labeler name",
    locationAndDateOfProduction: "Location and date of production of the product",
    detailedInformationOfTheManufacturing: "Detailed information of the manufacturing process, such as a batch or run number, or other identifying characteristics",
    informationToFacilitateAscertaining: "Any other information to facilitate ascertaining the specific source of the product",
    pleaseUploadTheseDocumentsByPressing: "Please upload these documents by pressing upload",
    category: "Category",
    exportingChineseCommodity: "Chinese Commodity Inspection Certificate",
    fumigationCertificates: "Fumigation certificates",
    packingRequirements: "Packing requirements for tracking labels",
    labelRequirements: "Label requirements",
    cpcDocumentation: "CPC documentation",
    epaDocumentation: "EPA documentation",
    tscaDocumentation: "TSCA documentation",
    siticDocumentation: "SICIT transportation safety report",
    msdsDocumentation: "MSDS documentation",
    ukcaDocumentation: "UKCA documentation",
    reachDocumentation: "REACH documentation",
    preimportFiling: "Pre-import filing",
    qualifiedDocumentation: "Qualified documentation",
    weeeDocumentation: "WEEE documentation",
    ceDocumentation: "CE documentation",
    lfgbCertification: "LFGB certification",
    dgccrfDocumentation: "DGCCRF certification",
    emarkCertification: "E-Mark certification",
    ecNo1935_2004: "(EC) No 1935/2004 documentation",
    transportationIdentificationReport: "Transportation identification report",
    un38_3Documentation: "UN38.3 documentation",
    dangerousGoodsDeclaration: "Dangerous goods declaration",
    below20WhForSingleAnd100WhForPack: "Below 20Wh for a single battery, 100Wh for a pack",
    above20WhForSingleAnd100WhForPack: "Above 20Wh for a single battery, 100Wh for a pack",
    cateringOrFoodContactProducts: "Catering / Food contact products",
    medicalInstruments: "Medical instruments",
    vehicleComponents: "Vehicle components",
    chemicals: "Chemicals",
    electricInstruments: "Electric instruments",
    nonElectricInstruments: "Non-electric instruments",
    rawBambooAndWooden: "Raw bamboo or wooden products",
    strongMagneticProducts: "Strong magnetic products",
    pensAndToysChildrenProducts: "Pens and Toys / Children's products",
    powders: "Powders",
    productsWithInternalBatteries: "Products with internal batteries",
    childrensProducts: "Children's products / toys",
    cosmetics: "Cosmetics",
    electronicProducts: "Household appliances / Electronic products",
    radioactiveProducts: "Radioactive products",
    woodenProducts: "Wooden products",
    nonElectricToys: "Non-electric toys",
    electricToys: "Electric toys",
    reportIsJustForTheBatteries: "Report is just for the batteries",
    productSurcharge: "Product surcharge +3RMB/KG",
    dontHaveDocument: "I don't have a CPC document",
    confirmSkusDoNotContain: "CONFIRM SKU’S ARE NOT WITHIN THE ABOVE CATEGORIES",
    uploadAllDocuments: "Please upload all required documents",
    selectSubcategory: "Please select subcategory",
    en71standard: "EN71 standard",
    en71standardAndIECStandard: "EN71 standard and EN IEC 62115:2020 standard",
    ceMarkUkcaMark: "CE mark (EU) / UKCA mark (UK)",
    productNameModelAndBrand: "Product name, model and brand, production serial number",
    informationOfTheManufacturer: "The name, address and contact information of the manufacturer",
    informationOfTheImporter: "The name, address and contact information of the importer",
    ukRepresentativeInformation: "EU Agent / UK Agent's name, address, and contact information",
    technicalParametersOfTheProduct: "Technical parameters of the product (voltage, current, power, etc.)",
    toyWarningSlogansAndSigns: "Toy warning slogans and signs",
    madeInChinaMark: "MADE IN CHINA mark of origin",
    radioactiveProductsDeliverOnly: "We only deliver the following products from this category: Blue Light, Laser Printer, Laser Engraving Machine, Laser Cutting Machine",
    manufacturersNameAndAddress: "Manufacturer's name and address",
    nameAndAddressOfResponsiblePerson: "Name and Address of EU&UK Responsible Person",
    countryOfOrigin: "Country of Origin",
    weightOrVolume: "Weight or volume",
    cosmeticsUsagePeriod: "Cosmetics usage period",
    anyPrecautionsForUse: "Any precautions for use (preventive measures to maintain product durability, adverse reactions when using the product, etc.)",
    identificationNumber: "Identification number (such as batch number)",
    functionsAndEffectsOfCosmetics: "Functions and effects of cosmetics",
    cosmeticIngredients: "Ingredients - These can only be provided on the packaging. Note that the composition of nanomaterials must be followed by “(nano)”",
    otherInformationForCosmeticProducts: "Other information (such as preservatives and UV filters, additional information must be provided)",
    logsNeedToBeFumigated: "Logs need to be fumigated",
    // Import products to SKUdrop
    importProductToSkuDrop: "IMPORT PRODUCTS TO SKUDROP",
    recived: "Received",
    availableCartons: "Available Cartons",
    uploadImagesButtonLabel: "Upload images only if cartons sizes are different to alert the customer.",
    uploadImagesButtonTitle: "UPLOAD IMAGES",
    labels: "Labels",
    incomingShipmnetsDashboardFiles: "Files",
    confirmIncomingShipment: "Confirm incoming shipment",
    contactName: "Contact Name",
    contactPhone: "Contact Phone",
    confirmIncomingShipmentDetails: "CONFIRM INCOMING SHIPMENT DETAILS BELOW",
    marksAcknowledge: "MASTER CARTON SHIPPING MARKS ACKNOWLEDGMENT",
    marksAcknowledgeQuestion: "Do you acknowledge that all your cartons must have shipping marks labels?",
    supplierContact: "SUPPLIER CONTACT DETAILS TO MANAGE DELIVERY",
    skudropWaitingCartons: "THE SKUDROP TEAM ARE NOW WAITING FOR YOUR CARTONS",
    important: "IMPORTANT",
    downloadIncomingShipmentDescription:
        "Print the incoming shipment label that's been emailed to you and get your supplier to add this label to every carton you're sending to SKUdrop.",
    thisHelpReceiveCartons: "THIS WILL HELP US RECEIVE YOUR CARTONS FASTER",
    downloadLabels: "DOWNLOAD LABELS",
    note: "NOTE",
    weHaveSendYouEmail: "We have sent you an email confirming our warehouse address for your supplier to send your cartons to.",
    paymentInvoice: "Payment Invoice",
    ukTariffChargeFiles: "UK Tariff Charge Files",
    file: "File",
    productDescription: "Product description",
    shortDescription: "Short description",
    selected: "Selected",
    supplier: "Supplier",
    deliveryDetails: "Delivery details",
    сartons: "Cartons",
    contactNumber: "Contact number",
    estDelivery: "Est. delivery",
    deliveryDetailsTitle: "Delivery details to receive your cartons",
    deliveryDetailsDescription: "This information is used to confirm with your factory when your cartons will be delivered to SKUdrop.",
    factoryContactName: "Factory contact name",
    contactPhoneNumber: "Contact phone number",
    factoryName: "Factory name",
    tariffAmount: "Tariff Amount",
    helpSectionTitle: "Help section",
    chooseVideoTitle: "Choose a video below to help you learn more about the SKUdrop platform. Or visit our YouTube knowledge base.",
    stillNeedHelp: "Still, need more help?",
    stillNeedHelpDescription: "If the above videos or connecting with our team via the live chat hasn't helped you, feel free to email the team at:",
    understandingDashboard: "Understanding the dashboard",
    profileSection: "Profile section",
    billingSection: "Billing section",
    pastShipmentsSection: "Past shipments area",
    sendProductToSkuDropSection: "Sending products to SKUdrop",
    creatingOutboundShipments: "Creating Outbound Shipments",
    addingProductSection: "Adding products or brands",
    howGetHelp: "How to get help",
    uploadTariffSheet: "Upload tariff sheet",
    SKUdropContactPerson: "SKUdrop contact person",
    CompanyPhoneNumber: "Company phone number",
    insurance: "Insurance",
    enterInsurance: "Enter Insurance",
    allDone: "ALL DONE!",
    sendProductToAmazonModalTitle1: "We have received your shipping information and FBA labels.",
    sendProductToAmazonModalTitle2: "Our warehouse staff will now go and prep your cartons straight away.",
    sendProductToAmazonModalTitle3: "In order to update your tracking ID's, please do not view your shipping plan in Seller Central.",
    sendProductToAmazonModalTitle4: "If you do, our system will be unable to automatically update your tracking ID's.",
    sendProductToAmazonModalTitle5: "I got it, don't show this again",
    weightMustBe: "Weight must be ",
    kgOrLes: "kg or less",
    remote_warehouse_surcharge: "Remote surcharge",
    created: "Created",
    company: "Company",
    bestRate: "Best rate",
    partialDelivery: "Partial Delivery",
    remaining: "Remaining",
    shipFromAddress: "Ship From Address",
    skudropWarehouseList: "SKUdrop warehouse list",
    add: "Add",
    addAddress: "Add Address",
    truck_weight_gte_30: "Truck weight 30kg - 40kg",
    truck_kg_longest_size_120_150: "Truck KG 120cm-150cm",
    truck_kg_longest_size_150_200: "Truck KG 150cm-200cm",
    truck_kg_longest_size_adaptive: "Truck KG {{from}}cm-{{to}}cm",
    truck_cbm_longest_size_120_150: "Truck CBM 120cm-150cm",
    truck_cbm_longest_size_150_200: "Truck CBM 150cm-200cm",
    truck_cbm_longest_size_adaptive: "Truck CBM {{from}}cm-{{to}}cm",
    enter: "Enter ",
    remoteWareouseFile: "Remote warehouse",
    truckInfoFile: "Truck info",
    minValue: "Minimum value",
    allTenants: "All tenants",
    ShipmentsWithPaymentIssue: "Shipments with payment issues",
    deleteAccount: "Delete or Disable Account",
    activateAccount: "Activate Account",
    disableAccount: "Disable Account",
    addingSupplier: "ADDING A SUPPLIER",
    editFactoryDetails: "EDIT FACTORY DETAILS",
    ifYouHaveChangedSuppliers: "If you have changed suppliers, or need to use an existing suppliers details in the system, select ADD NEW FACTORY DETAILS below.",
    addNewFactoryDetails: "ADD NEW FACTORY DETAILS",
    ifYouNeedToUpdateYourCurrentSuppliers: "If you need to update your current suppliers details, select UPDATE SUPPLIER DETAILS below.",
    updateSupplierDetails: "UPDATE SUPPLIER DETAILS",
    alreadyHaveSupplierSaved: "You already have a supplier saved in our system.",
    alreadyHaveSuppliersSaved: "You already have suppliers saved in our system.",
    useThisSupplier: "USE THIS SUPPLIER",
    ifThisProductUsesDifferentSupplier: "Or if this product uses a different supplier, click ADD NEW SUPPLIER.",
    addNewSupplier: "ADD NEW SUPPLIER",
    confirmSupplierDetails: "Confirm supplier details",
    onlyUpdateYourSupplierDetails: "Only update your supplier details if they have changed. For example, you may have changed suppliers.",
    ifNothingHasChanged: "If nothing has changed, please press DISMISS below.",
    makeChanges: "MAKE CHANGES",
    dismiss: "DISMISS",
    // FF Portal
    shippingDashboard: "Shipping Dashboard",
    invoice: "Invoice",
    packingList: "Packing List",
    detail: "Detail",
    taxRule: "Tax rule",
    unlock: "Unlock",
    lock: "Lock",
    details: "Details",
    productNameUpper: "PRODUCT NAME",
    volume: "VOLUME",
    shipMethod: "SHIP METHOD",
    picture: "PICTURE",
    shipper: "SHIPPER",
    shipperAddress: "SHIPPER ADDRESS",
    importer: "IMPORTER",
    importerAddress: "IMPORTER ADDRESS",
    consignee: "CONSIGNEE",
    consigneeAddress: "CONSIGNEE ADDRESS",
    containerList: "CONTAINER LIST",
    regular: "Regular",
    express: "Express",
    downloadPackingList: "Download packing list",
    searchContainers: "Search containers",
    sortBy: "Sort By",
    oldestToNewest: "Oldest to Newest",
    newestToOldest: "Newest to Oldest",
    shipped: "SHIPPED",
    notShipped: "NOT SHIPPED",
    all: "ALL",
    clearFilters: "Clear Filters",
    saveContainer: "SAVE CONTAINER",
    cancelUpper: "CANCEL",
    addContainer: "ADD CONTAINER",
    carrier: "CARRIER",
    containerNumber: "CONTAINER NO.",
    createdAt: "CREATED AT",
    space: "SPACE",
    totalCarton: "TOTAL CARTON",
    totalVolume: "TOTAL VOLUME",
    totalWeight: "TOTAL WEIGHT",
    lockUpper: "LOCK",
    noContainers: "There are no containers.",
    productListUpper: "PRODUCT LIST",
    assignToContainer: "ASSIGN TO CONTAINER",
    noAvailableContainers: "You don`t have available containers",
    noSpaceInContainer: "Not enough space in this container",
    selectUpper: "SELECT",
    noProducts: "There are no products.",
    description: "DESCRIPTION",
    material: "MATERIAL",
    usedFor: "USED FOR",
    quantity: "QUANTITY",
    unitValue: "UNIT VALUE",
    value: "VALUE",
    createCommercialInvoice: "Create commercial invoice",
    noInvoices: "There are no invoices.",
    searchByContainerId: "Search by Container NO, FBA ID, SKOS or SKOG",
    allShipmentsUpper: "ALL SHIPMENTS",
    noDetails: "There are no details.",
    customerDetails: "Customer details",
    customer: "Customer",
    shipment: "Shipment",
    order: "Order",
    vendorName: "Vendor Name",
    warehouse: "Warehouse",
    carrierLower: "Carrier",
    streetAddress: "Street Address",
    zipcode: "Zipcode",
    skuNumber: "SKU Number",
    productPictures: "Product pictures",
    productMaterial: "Product Material",
    productPurpose: "Product Purpose",
    cartonDetails: "Carton details",
    quantityLower: "Quantity",
    unitCost: "Unit Cost",
    totalPrice: "Total Price",
    otherDetails: "Other details",
    totalVolumeLower: "Total Volume",
    totalShippingCost: "Total Shipping Cost",
    netWeight: "Net Weight",
    downloadFile: "DOWNLOAD FILE",
    tariff: "TARIFF",
    additionalTariff: "ADDITIONAL TARIFF",
    merchandiseProcessingFee: "MERCHANDISE PROCESSING FEE",
    harbourMaintenance: "HARBOUR MAINTENANCE",
    actionUpper: "ACTION",
    noTaxRule: "There are no tax rules.",
    hsCodeUpper: "HS CODE",
    deleteContainer: "DELETE CONTAINER",
    editContainer: "Edit container",
    downloadPackingListTitle: "Download packing list",
    preparingFiles: "Preparing file...",
    createCommercialInvoiceTitle: "Create commercial invoice",
    size: "SIZE",
    users: "Users",
    freightForwarders: "Freight Forwarders",
    approve: "Approve",
    disapprove: "Disapprove",
    shipmentId: "SHIPMENT ID",
    productMaterialUpper: "PRODUCT MATERIAL",
    fbaWarehouseId: "FBA WAREHOUSE ID",
    invalidDateFormat: "Please type date in correct format, YYYY-MM-DD",
    warehouseUpper: "WAREHOUSE",
    selectPeriodUpper: "SELECT PERIOD",
    selectFilterPeriod: "SELECT FILTER PERIOD",
    saveFilterPeriod: "Save filter period",
    fromUpper: "FROM",
    toUpper: "TO",
    multipleHsCode: "Products with Multiple HS codes",
    filterByContainer: "Filter by container",
    searchByFbaIdOrSkos: "Search by FBA ID, SKOS or SKOG",
    containerUpper: "CONTAINER",
    containerId: "Container ID",
    containerNumberFull: "Container Number",
    awbNumber: "AWB number",
    trackingId: "Tracking ID",
    setContainerId: "Set Container ID",
    setAwbNumber: "Set AWB number",
    setTrackingId: "Set Tracking ID",
    trackingForMultipleShipments: "Tracking For Multiple Shipments",
    type: "Type",
    forNextShipments: "for next shipments",
    here: "here",
    uploadContainerFile: "Upload Container File",
    containerFileUploading: "Container File Uploading",
    followStepsBelowToUploadContainerFile: "Follow the steps below to upload the container file",
    fillTemplateWithNecessaryData: "Fill the template with the necessary data",
    selectCompletedTemplateByPressingButtonBelow: "Select the completed template by pressing the button below",
    afterSelectingFileUploadTemplate: "After selecting the file, upload your template",
    updateExistingContainer: "Update an existing container",
    selectFile: "Select file",
    uploadFile: "Upload file",
    filteredBy: "Filtered by",
    group: "Group",
    taxRuleMaxFifeDigits: "There must be a maximum of 5 digits after the comma",
    // Auto billing creation
    billingReport: "Billing Report",
    autoBillingCreation: "Auto Billing Creation",
    stepSelectBillingReportPeriod: "Step 1. Select billing report period",
    stepEnterRmbUsdConversionRate: "Step 3. Enter RMB/USD conversion rate",
    stepSelectBillingReportType: "Step 2. Select billing report type",
    stepClickDownloadButton: "Step {{step}}. Click DOWNLOAD button",
    freight: "Freight",
    tariffCamelCase: "Tariff",
    // News section
    userCommunication: "User Communication",
    skudropCustomerCommunication: "SKUdrop customer communication",
    chooseRecipients: "Choose recipients:",
    selectTenants: "Select tenants",
    selectAllTenants: "Select all:",
    tenants: "tenant(s)",
    banner: "Banner",
    typeContentHere: "Type content here...",
    notification: "Notification",
    emailNotification: "Email",
    send: "SEND",
    selectPeriod: "Select period",
    from: "From:",
    to: "To:",
    selectBannerPeriod: "SELECT BANNER PERIOD",
    selectTime: "Select time",
    savePeriod: "Save period",
    chooseDate: "Choose date",
    // Affiliate
    affiliate: "Affiliate",
    newAffiliate: "Create new affiliate",
    saveAffiliateDetails: "Save Affiliate Details",
    communityName: "Community Name",
    codeName: "Code Name",
    codeAtributes: "Code Attributes",
    community: "Community",
    code: "Code",
    codeUsage: "Code Usage",
    CurrentCBM: "Current CBM",
    accountsDetails: "Accounts Details",
    CurrentMonthCBMReceived: "Current Month CBM Received",
    AmountToPayTheAffiliate: "Amount to Pay the Affiliate",
    savePaymentsDetails: "Save Payment Details",
    payAffiliate: "Pay Affiliate",
    affiliateCode: "Affiliate Code",
    paid: "Paid",
    unPaid: "Unpaid",
    freeSubscriptionPer: "Free Subscription Expiry",
    prepFeeDiscount: "Prep Fee Discount",
    prepFeeDiscountExpiry: "Prep Fee Discount Expiry",
    activeCodes: "Active code",
    affiliateProgram: "Affiliate program",
    paypalEmail: "PayPal email to receive payment",
    maxUnits: "Max units per carton is",
    calculateTariff: "Calculate tariff",
    storage: "Storage",
    membershipFee: "Membership fee",
    shipping: "Shipping",
    tariffsAndTaxes: "Tariffs and taxes",
    prepFee: "Prep fee",
    sortedBy: "Sorted By",
    searchByIds: "Search by order id",
    filterBy: "Filter by",
    filterBySupplier: "Filter by supplier",
    searchByFBAId: "Search by FBA Id",
    downloadData: "Download data",
    downloadEntireList: "Download entire list",
    zeroCbmTenantsOnly: "ZERO CBM tenants only",
    // Settings
    shippingLabels: "KYT shipping labels",
    deleteAcc: "Delete Account",
    helpSection: "Help Section",
    settings: "Settings",
    allShipments: "All Shipments",
    knowledgeBase: "Knowledge Base",
    search: "Search...",
    allIncoming: "All Incoming",
    allOutgoing: "All Outgoing",
    uploadFileToSeeRates: "Please upload the rates file to see the tariffs",
    weightMustBeLessThan: "Weight must be less than",
    filter: "FILTER",
    allLowercase: "All",
    searchTitle: "Search",
    incoming: "Incoming",
    outgoing: "Outgoing",
    typeInYourSearch: "Type in your search above.",
    freightForwardersUnapproved: "Freight Forwarders Unapproved",
    numOfcartons: "No. Of Cartons",
    dateRecieved: "Date Received",
    shippingCost: "Shipping Cost",
    tariffCost: "Tariff Cost",
    tenant: "Tenant",
    allOutgoingCartons: "All Outgoing Cartons",
    allIncomingCartons: "All Incoming Cartons",
    searchByDate: "Search By Date",
    filterTariff: "Filter Tariffs",
    seaShipments: "Sea Shipments",
    airShipments: "Air Shipments",
    displayAirShipments: "Display Air Shipments",
    totalTariffAmount: "Total Tariff Amount",
    searchByHsCode: "Search by HS Code",
    communityOwnerRevenuePerCBM: "Community owner revenue per CBM",
    cmbFreeSubscriptionPeriod: "Community member benefits - free subscription period",
    cmbPrepFeeDiscount: "Community member benefits - prep fee discount",
    cmbPrepFeeDiscountTimePeriod: "Community member benefits - prep fee discount time period",
    numberOfShippingCreditsUsage: "Number of shipping credits usage",
    shippingCreditsAmount: "Shipping credits amount",
    editAffiliate: "Edit Affiliate",
    useShippingCredits: "Use Shipping Credits",
    shippingCredits: "Shipping Credits",
    signUps: "Sign Ups",
    balance: "Balance",
    creditUsage: "Credit usage",
    shippingCreditBalance: "Shipping credit balance",
    availableBalanceCredits: "Available credits on your balance",
    creditsWillBeUnavailable: "After changing the affiliate code, credits will be unavailable. Are you sure you want to continue?",
    uses: "Uses",
    typeOrderNumberOrTenantNameHere: "Type SKU, SKOG, Order, FBA, Invoice ID or Tenant name here",
    addMultipleValuesSeparatedByComma: "Add multiple values separated by a comma",
    affiliateCodeCapitalize: "Affiliate code",
    tenantName: "Tenant name",
    inbound: "Inbound",
    outbound: "Outbound",
    CBMStored: "CBM stored",
    totalRevenue: "Total revenue",
    totalRevenueGeneratedFromCode: "Total revenue generated from code",
    costToAcquireOneTenant: "Cost to acquire one tenant",
    ROI: "ROI",
    roiPerTenant: "ROI per tenant",
    totalInvestment: "Total investment",
    enterTotalInvestmentHere: "Enter total investment here...",
    data: "Data",
    addCode: "Add code",
    thisFieldCannotBeEmpty: "This field cannot be empty",
    onlyIntegersAreAllowed: "Only integers are allowed",
    signupExpirationPeriod: "Registration period with code",
    manageAffiliatesUpper: "MANAGE AFFILIATES",
    tenantDomainIsNotAvailable: "Tenant domain is not available",
    thereAreNoTenantsToDisplay: "There are no tenants to display",
    // Statistics area
    statistics: "Statistics",
    charts: "Charts",
    growth: "Growth",
    newUsers: "New users",
    activeUsers: "Active users",
    totalSubscribers: "Total subscribers",
    averageStorageUsers: "Av Storage/Users",
    averageShipmentCBMUsers: "Av Shipment CBM/Users",
    shipments: "Shipments",
    inboundShipments: "Inbound Shipments",
    totalInboundCBM: "Total Inbound CBM",
    outboundShipments: "Outbound Shipments",
    outboundCBM: "Outbound CBM",
    outboundCartons: "Outbound Cartons",
    sellers: "Sellers",
    smallSellers: "Small Sellers 0-10 CBM",
    mediumSellers: "Medium Sellers 11-68 CBM",
    largeSellers: "Large Sellers 69+ CBM",
    enterpriseSellers: "Enterprise Sellers 100+ CBM",
    importantNote: "IMPORTANT NOTE",
    filterByFcId: "Filter by FC ID",
    date: "DATE",
    requiresConfirmation: "Requires Confirmation",
    productUse: "Product Use",
    supplierId: "Supplier ID",
    uk_additional_fee_for_weight: "UK Additional Fee for Weight",
    uk_kg_min_weight: "UK Carton Min Weight",
    uk_moq_min_weight: "UK MOQ Min Weight",
    uk_mid_size_gte_70_extra: "UK Mid Size Gte 70 Extra",
    uk_size_gte_100_extra: "UK Size Gte 100 Extra",
    uk_sizes_formula_extra: "UK Size Formula Extra",
    noProductsFromAmazon: "There are no products. Please import products from Amazon",
    supplierIdUpper: "SUPPLIER ID",
    growsCharts: "Growth Charts",
    shipmentCharts: "Shipments Charts",
    downloadReport: "Download report",
    paymentIssues: "Payment Issues",
    pay: "Pay",
    etdOldestToNewest: "ETD Oldest to Newest",
    etdNewestToOldest: "ETD Newest To Oldest",
    etaOldestToNewest: "ETA Oldest to Newest",
    etaNewestToOldest: "ETA Newest To Oldest",
    amazonLink: "Amazon Link",
    reporting: "Reporting",
    SKUdropReports: "SKUdrop Reports",
    reportPeriod: "Report Period",
    selectReportingPeriod: "SELECT A REPORTING PERIOD",
    shortStorageReport: "Short Storage Report:",
    storageOverviewReport: "Storage Overview Report:",
    extendedStorageReport: "Extended Storage Report:",
    whatIsCurrentlyBeingStoredInSKUdrop: "What is currently being stored in SKUdrop",
    selectTheDateRangeForShortStorageReport: "Select the date range then download the short storage report",
    dailyStorageDataForTheSelectedDateRange: "Daily storage data for the selected date range",
    selectTheDateRangeForExtendedStorageReport: "Select the date range then download the extended storage report",
    dailySKUStorageDataForTheSelectedDateRange: "Daily SKU storage data for the selected date range",
    searchForCountryCode: "Search by Country Code",
    allAddress: "All Address",
    uploadDocument: "Upload Document",
    uKShippingPreparation: "UK Shipping Preparation",
    vatCertificateUpload: "VAT Certificate Upload",
    noteDocusignUk: "NOTE: Certificates using the HRMC address will not be accepted",
    ukVatNumber: "UK VAT Number",
    eoriNumber: "EORI Number",
    vatRegistrarCompany: "VAT Registrar Company",
    vatBusinessName: "Business Name",
    vatRegistrarLegalRepresentative: "VAT Registrar Legal Representative",
    customClearanceAuthorisation: "Custom Clearance Authorisation and Authorisation of Import PVAT",
    readAndSign: "Read and Sign",
    cogsReporting: "COGS Reporting",
    // Past shipments
    ctns: "CTNs",
    cbmUpper: "CBM",
    noShippmentsToSkudrop: "There are no products to send to SKUdrop.",
    UKApprovals: "UK Approvals",
    vatCertificate: "VAT certificate",
    vatRegistrarCompanyDetails: "VAT Registrar Company details",
    customsClearanceAuthorisation: "Customs Clearance Authorisation",
    authorisationOfImportPvat: "Authorisation of Import PVAT",
    thereNoDocumentsToApprove: "There are no documents to approve.",
    docusignUkRequireDocumnets: "We require certain documents and information in order to ship to the UK on your behalf. Please provide the information below.",
    youCantSendProductsToSkudrop: "You can't send cartons to SKUdrop until your UK shipping documents are approved.",
    // Integrations modal
    connectingSkudropTo: "Connecting SKUdrop to Seller Central",
    connectingYourSellerCentral: "Connecting your Seller Central account to SKUdrop will help you send cartons to the relevant marketplace.",
    pleaseChooseTheMarketplace: "Please choose the marketplace that you'd like to connect.",
    onceYouImportYourProducts: "Once you import your products from a marketplace, you can import products from other marketplaces later.",
    // HS validation modal
    HSValidation: "HS Code Validation",
    useCorrectHSCodeformat: "Please use the correct HS Code format which is 0000.00.00.00",
    VerifyingHS: "Checking the HS code...",
    HSIsVerified: 'Your HS code is verified, press "CONFIRM" to continue',
    detectProblemsWithHS: "This HS Code is not valid, please enter a valid HS Code",
    enterHSInFieldAbove: 'Enter the HS code in the field above and then press "CHECK"',
    checkUpper: "CHECK",
    generetingDocusignFile: "We are generating your files. You will be redirected shortly.",
    ukSeaShipments: "UK Sea Shipments",
    ukTruckShipments: "UK Truck Shipments",
    statusUpper: "STATUS",
    cantFindHS: "Can't find your HS code?",
    clickHereUpper: "CLICK HERE",
    // HS info modal
    usReason:
        "The reason for this is because the HS Code is not valid for the US 2023 HTS List. Our system checks the code with the official US HTS website to make sure they are currently valid.",
    usWePrevent: "We do this to prevent any invalid codes used when shipping your products that may cause the shipment to be held up with US Customs.",
    usHsInfoLink: "You could ask your supplier for the new valid 2023 US HS Code or you can search for the new HS Code yourself at ",
    usYoutubeVideoLink: "Here is a link to a helpful video on HS Codes: ",
    usRecommendProfessional1: "The other option is to get a professional to help locate the correct code, we recommend John Burhans at ",
    usRecommendProfessional2: "they offer SKUdrop customers a discount.",
    ukReason: "The reason for this error is because the HS Code you have entered is not a valid UK Tariff code.",
    ukWePrevent:
        "Our system checks the code with the official UK Tariff website to make sure it is correct. This is done to prevent invalid codes being used when shipping products that may cause the shipment to be held up by UK Customs.",
    ukHsInfoList: "You could ask your supplier for a new valid UK Tariff Code or you can search for the new HS Code yourself at ",
    europeReason: "The reason for this error is because the HS Code you have entered is not a valid Europe Tariff code.",
    europeWePrevent:
        "Our system checks the code with the official Europe Tariff website to make sure it is correct. This is done to prevent invalid codes being used when shipping products that may cause the shipment to be held up by Europe Customs.",
    europeHsInfoList: "You could ask your supplier for a new valid Europe Tariff Code or you can search for the new HS Code yourself at ",
    // Bulk SKU upload modal
    uploadSkusInBulk: "UPLOAD SKU'S IN BULK",
    bulkSkuUploading: "Bulk SKU Uploading",
    followStepsToUploadDetailsBulk: "Follow the steps below to upload SKU details in bulk.",
    step: "Step",
    downloadBulkSkuUploadTemplate: "Download the bulk SKU upload template.",
    fillInTemplateWithDetails: "Fill in the template with the details of your SKU's.",
    ensureYouSelectCorrectMeasurement: "Please ensure you select the correct measurement type.",
    uploadCompletedTemplateByPressing: "Upload the completed template by pressing the upload button below.",
    downloadTemplate: "Download template",
    uploadTemplate: "UPLOAD TEMPLATE",
    pleaseUploadValidExcelFile: "Please upload a valid excel file",
    // Switch Tab Modal
    warning: "Warning",
    continue: "Continue",
    createShippingPlansForOneMarket: "Create shipping plans for one market at a time.",
    enteredDataWillBeRemoved: "Entered data will be removed when going to a new tab.",
    // Track shipments
    shipmentTracker: "Track Shipments",
    yourLiveShipments: "Your live shipments",
    latestShipped: "Latest shipped",
    nextToArrive: "Next to Arrive",
    noShipmentsToTrack: "There are no shipments to track",
    enterSkuHereToTrack: "Search for SKU: Type SKU then press enter...",
    noSKUsMatchThisSearch: "No SKU's match this search.",
    // Tenant manual quoting
    liveQuotes: "Live Quotes",
    requestAQuote: "Request a quote",
    thereAreNoLiveQuotes: "There are no live quotes",
    accept: "Accept",
    reject: "Reject",
    quote: "Quote",
    prepFeesOf: "+ PREP FEES OF",
    pending: "Pending",
    labelRequiredForPrepNo: "Label required for prep - No",
    destinationFC: "Destination FC",
    quoteConfirmation: "QUOTE CONFIRMATION",
    toAmazon: "To Amazon",
    seaShipping: "Sea shipping",
    airShipping: "Air shipping",
    medium: "Medium",
    serviceLoverCase: "service",
    cartonsLoverCase: "cartons",
    cartonLoverCase: "carton",
    acceptShipmentQuote: "ACCEPT SHIPMENT QUOTE",
    rejectQuote: "Reject Quote",
    requestFreightQuote: "Request a Freight Quote",
    seaUpperCase: "SEA",
    airUpperCase: "AIR",
    stepSelectDestination: "Step 2 - Select Destination",
    selectMarketPlace: "Select market place",
    enterFbaFC: "Enter FBA FC",
    enterUpperCase: "ENTER",
    stepAddDestination: "Step 2 - Add Destination",
    addressLine1: "Address Line 1",
    zipPostalCode: "Zip/Postal code",
    submitShipmentForQuote: "SUBMIT SHIPMENT FOR QUOTE",
    selectFCForSKU: "Select FC for SKU",
    empty: "Empty",
    addFC: "Add FC",
    toCamelCase: "To",
    fbaReferenceNo: "FBA Reference NO.",
    unitsSlashCtn: "units/ctn",
    receivingAddressHasLoadingDock: "Receiving address has loading dock?",
    bulkSkuUpload: "Bulk SKU Upload",
    fillInTheDetailsBelowAndThenUploadTheRelevantFileForEachShipment: `Fill in the details below and then\nupload the relevant csv file for each shipment.`,
    quoteDetails: "Quote details",
    destinationWarehouses: "Destination warehouses",
    destinationAddress: "Destination address",
    deliveryType: "Delivery type",
    sea: "Sea",
    air: "Air",
    // Quote Orders
    quoteOrders: "Quote Orders",
    pendingQuoteOrders: "Pending Quote Orders",
    quoteFile: "Quote file",
    quotes: "Quotes",
    allQuotingOrders: "All quote orders",
    finalQuote: "Final Quote",
    confirmQuote: "Confirm Final Quote",
    confirmQuoteOrder: "CONFIRM QUOTE ORDER",
    quoteUpdating: "QUOTE UPDATING",
    updateQuoteOrder: "UPDATE QUOTE ORDER",
    noQuoteRequests: "There are no quote requests.",
    noPendingQuoteRequests: "There are no pending quote requests.",
    destinationCountry: "Destination country",
    quoteRequests: "Quote requests",
    quoteRequestsWithPaymentIssues: "Quote requests with payment issues",
    pendingQuoteRequests: "Pending quote requests",
    pendingQuoteRequestsWithPaymentIssues: "Pending quote requests with payment issues",
    quoteRequestsCamelCase: "Quote Requests",
    activateManualQuoting: "Activate manual quoting",
    // Notification Settings
    notifications: "Notifications",
    selectSubscribers: "Select subscribers",
    noEmailsFound: "No emails found",
    incomingShipmentsCapitalize: "Incoming Shipments",
    outgoingShipmentsCapitalize: "Outgoing Shipments",
    inventoryAndProductsCapitalize: "Inventory and Products",
    billingAndPaymentCapitalize: "Billing and Payment",
    AccountRelatedNotificationsCapitalize: "Account Related Notifications",
    saveChanges: "Save changes",
    incomingShipmentsNotificationDescription: "Select the email(s) to receive incoming shipment notifications.",
    outgoingShipmentsNotificationDescription: "Select the email(s) to receive outgoing shipment notifications.",
    inventoryAndProductsNotificationDescription: "Select the email(s) to receive inventory and product notifications.",
    billingAndPaymentNotificationDescription: "Select the email(s) to receive billing and payment notifications.",
    accountRelatedNotificationDescription: "Select the email(s) to receive account notifications.",
    // Operations message
    totalSizeOfImagesShouldNotExceed: "The total size of images should not exceed",
    pleaseUploadFileSmallerThan: "Please upload a file smaller than {{size}}",
    imageCompressionInProgress: "Image compression in progress. Please wait a moment.",
    somethingWentWrongWhileCompressingTheImages: "Something went wrong while compressing the images",
    somethingWentWrongDuringReceivingCartons: "Something went wrong during receiving cartons",
    pleaseUploadRatesFile: "Please upload a rates file",
    pleaseSelectShippingService: "Please select a shipping service",
    pleaseSelectContainerSize: "Please select a container size",
    atLeastOneSubscriberShouldBeSelected: "At least one subscriber should be selected",
    bulkUploadIsInProgressWeWillNotify: "Bulk upload is in progress. We will notify you when the upload is completed.",
    // Warning messages
    youCanSelectUpToOrdersAtATime: "You can select up to {{count}} orders at a time",
    // Validation messages
    pleaseEnterValidFbaID: "Please enter valid FBA ID",
    endCantBeInPast: "End date can`t be in the past",
    timeFieldRequired: "Time fields is required",
    timeEndMustBeLater: "Time end must be later than start",
    pleaseChooseRecipients: "Please choose list of recipients or press select all",
    pleaseEnterOneField: "Please enter one of the three fields",
    theValueIsIncorrect: "The value is incorrect",
    maximum90Characters: "Maximum 90 characters",
    maximum120Characters: "Maximum 120 characters",
    dateIsRequired: "Date is required",
    timeIsRequired: "Time is required",
    onlyANumberIsAllowed: "Only a number is allowed",
    atLeastOneTrackingIdIsRequired: "At least one Tracking ID is required",
    onlyPdfFilesAreAllowed: "Only PDF files are allowed",
    fbaFCAlreadyAdded: "FBA FC already added",
    invalidFbaFC: "Invalid FBA FC",
    trackingIdsThatHaveSameValue: "All tracking ids that have the same value must be identical",
    pleaseUploadValidPdfFile: "Please upload a valid PDF file",
    pleaseUploadValidPdfFiles: "Please upload a valid PDF files",
    pleaseUploadValidCsvFile: "Please upload a valid CSV file",
    youDoNotHaveEnoughCartonsForProductAvailableNumberOfCartonsForSkuIs:
        "You do not have enough cartons for the product. The available number of cartons for {{sku}} ({{units_per_carton}} units/ctn) is {{available_cartons}}.",
    maximumNumberOfCartonsForThisProductIs: "The maximum number of cartons for this product is {{available_cartons}}.",
    atLeastOneMarketplaceShouldBeSelected: "At least one marketplace should be selected",
    anOrderWithThisFieldHasAlreadyBeenAdded: "An order with this {{name}} has already been added",
    // Confirmation messages
    capacityOfDesiredContainerTooSmall: "The capacity of the desired container is too small, the entered number of cartons will be cleared. Do you want to continue?",
};
