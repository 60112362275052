import axios from "axios";
import Cookies from "universal-cookie";

import {
    AmazonProduct,
    CreateShipmentPlanRequestType,
    TSendToAmazonIntegration,
    SendToAmazonActionTypes,
    SendToAmazonThunkType,
    SetErrorAmazonProductType,
    SetIntegrationsType,
    SetLoadingMessageType,
    SetReadyToAmazonProductType,
    SetShipmentPlanType,
    SetLoadingIntegrationsType,
    TSetLoadingShipmentPlans,
    SetReadyToAmazonFFProductType,
    TStateSendToAmazonProducts,
    TSetFFDetails,
    TFFDetailsResults,
    TStateOwnFFSendToAmazonProducts,
    TOwnFFSendToAmazonComponents,
    TPostCreateFlatFileData,
    TSetOwnFFPlans,
    TStateOwnFFPlans,
    TOwnFFPlanItem,
    TStateOwnFFAwdPlans,
    TSetOwnFFAwdPlans,
    TOwnFFAwdPlanItem,
    TSendToAmazonLoadingStates,
    TSetLoadingStates,
    TAmazonPlanSplits,
    TConfirmShippingPlanReqData,
} from "./types/SendToAmazon/sendToAmazon.types";
import { getUser } from "./authReducer";
import { sendToAmazon } from "../api/sendToAmazonAPI";
import { SetLoadingType } from "./types/AuthTypes/auth.types";
import { sortIntegrationList } from "../components/common/utils";
import { displayError, displaySuccess } from "../hooks/useErrorHandler";

export const SET_READY_TO_AMAZON_PRODUCT = "SET_READY_TO_AMAZON_PRODUCT";
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR_AMAZON_PRODUCT = "SET_ERROR_AMAZON_PRODUCT";
export const SET_SHIPMENT_PLAN = "SET_SHIPMENT_PLAN";
export const SET_LOADING_MESSAGE = "SET_LOADING_MESSAGE";
export const SET_AMAZON_INTEGRATIONS_LIST = "SET_AMAZON_INTEGRATIONS_LIST";
export const SET_LOADING_SHIPPING_PLANS = "SET_LOADING_SHIPPING_PLANS";
export const SET_LOADING_INTEGRATION = "SET_LOADING_INTEGRATION";
export const SET_LOADING_SHIPMENT_PLANS = "SET_LOADING_SHIPMENT_PLANS";
export const SET_READY_TO_AMAZON_FF_PRODUCT = "SET_READY_TO_AMAZON_FF_PRODUCT";
export const SET_FF_DETAILS = "SET_FF_DETAILS";
export const SET_OWN_FF_PLANS = "SET_OWN_FF_PLANS";
export const SET_OWN_FF_AWD_PLANS = "SET_OWN_FF_AWD_PLANS";
export const SET_LOADING_STATES = "SET_LOADING_STATES";

type InitalStateType = {
    sendToAmazonProduct: TStateSendToAmazonProducts | null;
    isLoading: boolean;
    isLoadingMessage: null | string;
    isLoadingIntegrations: boolean;
    isLoadingShipmentPlans: boolean;
    isErrorAmazonProduct: null | boolean | string;
    shipmentPlan: { [key: string]: TAmazonPlanSplits | null } | null;
    amazonIntegarationList: null | TSendToAmazonIntegration[];
    sendToAmazonFFProducts: TStateOwnFFSendToAmazonProducts | null;
    ffDetails: TFFDetailsResults[] | null;
    own_ff_plans: TStateOwnFFPlans | null;
    own_ff_awd_plans: TStateOwnFFAwdPlans | null;
    loadingStates: TSendToAmazonLoadingStates;
};

let initialState: InitalStateType = {
    sendToAmazonProduct: null,
    isLoading: false,
    isLoadingMessage: null,
    isLoadingIntegrations: false,
    isLoadingShipmentPlans: false,
    isErrorAmazonProduct: null,
    shipmentPlan: null,
    amazonIntegarationList: null,
    sendToAmazonFFProducts: null,
    ffDetails: null,
    own_ff_plans: null,
    own_ff_awd_plans: null,
    loadingStates: {
        isLoadingSKUdropAmazonProducts: false,
        isLoadingOwnFFOffAmazonProducts: false,
        isLoadingOwnFFDetails: false,
        isLoadingPostShipmentPlan: false,
        isLoadingOwnFFPlans: false,
    },
};

const sendToAmazonReducer = (state = initialState, action: SendToAmazonActionTypes): InitalStateType => {
    switch (action.type) {
        case SET_AMAZON_INTEGRATIONS_LIST: {
            return {
                ...state,
                amazonIntegarationList: action.data,
            };
        }
        case SET_READY_TO_AMAZON_PRODUCT: {
            return {
                ...state,
                sendToAmazonProduct: { ...state?.sendToAmazonProduct, [action.integrationId]: action.data },
            };
        }
        case SET_SHIPMENT_PLAN: {
            return {
                ...state,
                shipmentPlan: { ...state.shipmentPlan, [action.integration]: action.data },
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_LOADING_MESSAGE: {
            return {
                ...state,
                isLoadingMessage: action.data,
            };
        }
        case SET_ERROR_AMAZON_PRODUCT: {
            return {
                ...state,
                isErrorAmazonProduct: action.data,
            };
        }
        case SET_LOADING_INTEGRATION: {
            return {
                ...state,
                isLoadingIntegrations: action.data,
            };
        }
        case SET_LOADING_SHIPMENT_PLANS: {
            return {
                ...state,
                isLoadingShipmentPlans: action.data,
            };
        }
        case SET_READY_TO_AMAZON_FF_PRODUCT: {
            return {
                ...state,
                sendToAmazonFFProducts: {
                    ...state?.sendToAmazonFFProducts,
                    [action.component]: { ...state?.sendToAmazonFFProducts?.[action.component], [action.integrationId]: action.data },
                },
            };
        }
        case SET_FF_DETAILS: {
            return {
                ...state,
                ffDetails: action.data,
            };
        }
        case SET_OWN_FF_PLANS: {
            return {
                ...state,
                own_ff_plans: { ...state?.own_ff_plans, [action.integration_id]: action.data },
            };
        }
        case SET_OWN_FF_AWD_PLANS: {
            return {
                ...state,
                own_ff_awd_plans: { ...state?.own_ff_awd_plans, [action.integration_id]: action.data },
            };
        }
        case SET_LOADING_STATES: {
            return {
                ...state,
                loadingStates: {
                    ...state.loadingStates,
                    ...action.data,
                },
            };
        }
        default:
            return state;
    }
};

export const SetAllIntegrations = (data: TSendToAmazonIntegration[]): SetIntegrationsType => ({
    type: SET_AMAZON_INTEGRATIONS_LIST,
    data,
});
export const SetReadyToAmazonProduct = (integrationId: string, data: AmazonProduct[]): SetReadyToAmazonProductType => ({
    type: SET_READY_TO_AMAZON_PRODUCT,
    integrationId,
    data,
});
export const SetReadyToAmazonFFProduct = (component: TOwnFFSendToAmazonComponents, integrationId: string, data: AmazonProduct[]): SetReadyToAmazonFFProductType => ({
    type: SET_READY_TO_AMAZON_FF_PRODUCT,
    component,
    integrationId,
    data,
});
export const SetShipmentPlan = (integration: string, data: TAmazonPlanSplits | null): SetShipmentPlanType => ({
    type: SET_SHIPMENT_PLAN,
    data,
    integration,
});
export const SetLoading = (loading: boolean): SetLoadingType => ({
    type: SET_LOADING,
    data: loading,
});
export const SetLoadingIntegrations = (loading: boolean): SetLoadingIntegrationsType => ({
    type: SET_LOADING_INTEGRATION,
    data: loading,
});
export const SetLoadingMessage = (message: null | string): SetLoadingMessageType => ({
    type: SET_LOADING_MESSAGE,
    data: message,
});
export const SetErrorAmazonProduct = (error: null | boolean | string): SetErrorAmazonProductType => ({
    type: SET_ERROR_AMAZON_PRODUCT,
    data: error,
});
export const SetLoadingShipmentPlans = (loading: boolean): TSetLoadingShipmentPlans => ({
    type: SET_LOADING_SHIPMENT_PLANS,
    data: loading,
});
export const SetFFDetails = (data: TFFDetailsResults[]): TSetFFDetails => ({
    type: SET_FF_DETAILS,
    data,
});

export const SetOwnFFPlans = (integration_id: string, data: TOwnFFPlanItem[]): TSetOwnFFPlans => ({
    type: SET_OWN_FF_PLANS,
    integration_id,
    data,
});

export const SetOwnFFAwdPlans = (integration_id: string, data: TOwnFFAwdPlanItem[]): TSetOwnFFAwdPlans => ({
    type: SET_OWN_FF_AWD_PLANS,
    integration_id,
    data,
});

export const SetLoadingStates = (data: Partial<TSendToAmazonLoadingStates>): TSetLoadingStates => ({
    type: SET_LOADING_STATES,
    data,
});

export const getAllIntegrations = (cookies: Cookies): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingIntegrations(true));
            const response = await sendToAmazon.getAllIntegrations(cookies);
            dispatch(SetAllIntegrations(sortIntegrationList(response.data.results || [])));
            dispatch(SetLoadingIntegrations(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getReadyToAmazonProduct = (
    cookies: Cookies,
    integration_id: string,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void
): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            if (!offset) {
                dispatch(SetLoadingStates({ isLoadingSKUdropAmazonProducts: true }));
            }

            const response = await sendToAmazon.getAmazonProduct(cookies, integration_id, searchTerm, items, offset);

            setProductAllNext(response.data.next !== null ? true : false);

            dispatch(SetErrorAmazonProduct(null));
            dispatch(SetReadyToAmazonProduct(integration_id, response.data.results));

            if (!offset) {
                dispatch(SetLoadingStates({ isLoadingSKUdropAmazonProducts: false }));
            }
        } catch (e) {
            console.log(e);
            dispatch(SetLoadingStates({ isLoadingSKUdropAmazonProducts: false }));
        }
    };
};

export const getShipmentPlan = (cookies: Cookies, integration_id: string): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingShipmentPlans(true));

            const response = await sendToAmazon.getShipmentPlan(cookies, integration_id);

            dispatch(SetShipmentPlan(integration_id, response.data));

            dispatch(SetLoadingShipmentPlans(false));
        } catch (e) {
            console.log(e);
            dispatch(SetLoadingShipmentPlans(false));
        }
    };
};

export const deleteUnconfirmedShipmentPlan = (
    planId: string,
    integrationId: string,
    cookies: Cookies,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void
): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingShipmentPlans(true));

            await sendToAmazon.deleteUnconfirmedShipmentPlan(planId, cookies);

            dispatch(getShipmentPlan(cookies, integrationId));
            dispatch(getReadyToAmazonProduct(cookies, integrationId, searchTerm, items, offset, setProductAllNext));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingShipmentPlans(false));
                displayError(e.response.data, "An error occurred while deleting the shipment plan", { isAWS: true });
            }
        }
    };
};

export const createShipmentPlan = (
    cookies: Cookies,
    sendToAmazonProduct: AmazonProduct[],
    integrationId: string,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void
): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingMessage("We are creating your shipping plans. If you have lots of SKU’s this could take up to a minute."));
            dispatch(SetLoadingStates({ isLoadingPostShipmentPlan: true }));

            const data: CreateShipmentPlanRequestType[] = [];

            sendToAmazonProduct.map((product) => {
                if (product.zero_number_of_cartons > 0) {
                    data.push({
                        template_carton_id: product.get_cartons_data_for_product_ship_to_amazon.template_carton_id,
                        sku: product.sku,
                        asin: product.asin,
                        number_of_units: product.zero_number_of_units,
                    });
                }
            });

            await sendToAmazon.createUnconfirmedShipmentPlan(cookies, data, integrationId);

            await dispatch(getShipmentPlan(cookies, integrationId));
            await dispatch(getReadyToAmazonProduct(cookies, integrationId, searchTerm, items, offset, setProductAllNext));

            dispatch(SetLoadingMessage(null));
            dispatch(SetLoadingStates({ isLoadingPostShipmentPlan: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                await dispatch(getShipmentPlan(cookies, integrationId));
                await dispatch(getReadyToAmazonProduct(cookies, integrationId, searchTerm, items, offset, setProductAllNext));

                dispatch(SetLoadingMessage(null));
                dispatch(SetLoadingStates({ isLoadingPostShipmentPlan: false }));

                displayError(e.response.data, "An error occurred while creating the shipment plan", { isAWS: true });
            }
        }
    };
};

export const submitShipment = (
    cookies: Cookies,
    reqData: TConfirmShippingPlanReqData,
    use_credits: boolean,
    integrationId: string,
    onSuccess: () => void
): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            dispatch(SetLoadingMessage("We are submitting your shipping plans. If you have lots of SKU’s this could take up to a minute."));

            await sendToAmazon.submitShipment(cookies, reqData);

            if (use_credits) {
                dispatch(getUser(cookies));
            }

            dispatch(SetShipmentPlan(integrationId, null));

            onSuccess();

            dispatch(SetErrorAmazonProduct(false));
            dispatch(SetLoading(false));
            dispatch(SetLoadingMessage(null));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "An error occurred while submitting the shipping plan", { isAWS: true });

                dispatch(getShipmentPlan(cookies, integrationId));
                dispatch(SetLoading(false));
                dispatch(SetLoadingMessage(null));
            }
        }
    };
};

export const getReadyToAmazonFFProduct = (
    cookies: Cookies,
    integration_id: string,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void,
    component: TOwnFFSendToAmazonComponents
): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            if (!offset && component === "off_amazon") {
                dispatch(SetLoadingStates({ isLoadingOwnFFOffAmazonProducts: true }));
            }

            const response = await sendToAmazon.getOwnFFOffAmazonAmazonProduct(cookies, integration_id, searchTerm, items, offset);

            setProductAllNext(response.data.next !== null ? true : false);

            dispatch(SetReadyToAmazonFFProduct(component, integration_id, response.data.results));

            if (!offset && component === "off_amazon") {
                dispatch(SetLoadingStates({ isLoadingOwnFFOffAmazonProducts: false }));
            }
        } catch (e) {
            console.log(e);
            if (!offset && component === "off_amazon") {
                dispatch(SetLoadingStates({ isLoadingOwnFFOffAmazonProducts: false }));
            }
        }
    };
};

export const postCreateOwnFFOffAmazonPlan = (
    cookies: Cookies,
    formData: FormData,
    integrationId: string,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void,
    onSuccess: () => void
): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingMessage("We are sending your shipment information to the warehouse, if you have lots of SKUs this could take up to a minute."));
            dispatch(SetLoading(true));

            await sendToAmazon.postCreateOwnFFShipmentPlan(cookies, formData);

            onSuccess(); // clear changed products & clear all lazy items & reset lazy loading offset & reset all page changes

            await dispatch(getFFDetails(cookies));
            await dispatch(getReadyToAmazonFFProduct(cookies, integrationId, searchTerm, items, offset, setProductAllNext, "off_amazon"));

            dispatch(SetLoading(false));
            dispatch(SetLoadingMessage(null));

            displaySuccess("Shipment sent to the warehouse successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "An error occurred while sending the shipment plan to the warehouse");

                dispatch(SetLoading(false));
                dispatch(SetLoadingMessage(null));
            }
        }
    };
};

export const getFFDetails = (cookies: Cookies): SendToAmazonThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOwnFFDetails: true }));

            const response = await sendToAmazon.getFFDetails(cookies);

            dispatch(SetFFDetails(response.data.results));

            dispatch(SetLoadingStates({ isLoadingOwnFFDetails: false }));
        } catch (e) {
            console.log(e);

            dispatch(SetLoadingStates({ isLoadingOwnFFDetails: false }));
        }
    };
};

export const postCreateFlatFile = (cookies: Cookies, data: TPostCreateFlatFileData, onSuccess: () => void): SendToAmazonThunkType => {
    return async () => {
        try {
            await sendToAmazon.createFlatFile(cookies, data).then((res) => {
                window.open(res.data.url, "_blank");
                onSuccess();
            });
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "An error occurred while creating the flat file");
            }
        }
    };
};

export const getOwnFFPlans = (integration_id: string, withLoading?: boolean): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            if (withLoading) {
                dispatch(SetLoadingStates({ isLoadingOwnFFPlans: true }));
            }

            const cookies = getState().auth.cookies;

            const response = await sendToAmazon.getOwnFFPlans(cookies, integration_id);

            dispatch(SetOwnFFPlans(integration_id, response.data.results));

            if (withLoading) {
                dispatch(SetLoadingStates({ isLoadingOwnFFPlans: false }));
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                if (withLoading) {
                    dispatch(SetLoadingStates({ isLoadingOwnFFPlans: false }));
                }

                displayError(e.response.data);
            }
        }
    };
};

export const deleteOwnFFPlans = (integration_id: string, ids: string[]): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            await sendToAmazon.deleteOwnFFPlans(cookies, ids);

            await dispatch(getOwnFFPlans(integration_id)); // await is required for the correct order of actions in the function that calls this thunk
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

export const postCreateOwnFFToAmazonShipment = (integration_id: string, formData: FormData, onSuccess: () => void): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingMessage("We are precessing your shipment information, if you have lots of SKUs this could take up to a minute."));
            dispatch(SetLoading(true));

            const cookies = getState().auth.cookies;

            await sendToAmazon.postCreateOwnFFToAmazonShipment(cookies, formData);

            onSuccess(); // reset own ff changes

            await dispatch(getFFDetails(cookies));
            await dispatch(getOwnFFPlans(integration_id));

            dispatch(SetLoading(false));
            dispatch(SetLoadingMessage(null));

            displaySuccess("Shipment created successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                dispatch(SetLoadingMessage(null));
                displayError(e.response.data, "An error occurred while sending the shipment plan to the warehouse");
            }
        }
    };
};

export const postProcessOwnFFToAmazonLabels = (data: FormData, integration_id: string, processStatus: (status: number) => void): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            const response = await sendToAmazon.postProcessOwnFFToAmazonLabels(cookies, data);

            await dispatch(getOwnFFPlans(integration_id));

            processStatus(response.status); // clear uploaded labels from state
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                processStatus(e.response.status); // change processing status
                displayError(e.response.data);
            }
        }
    };
};

export const postProcessOwnFFToAmazonCsv = (formData: FormData, integration_id: string, onSuccess: (plan_id?: string) => void, isAwd?: boolean): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            const response = await sendToAmazon.postProcessOwnFFToAmazonCsv(cookies, formData);

            if (isAwd) {
                await dispatch(getOwnFFAwdPlans(integration_id));
            } else {
                await dispatch(getOwnFFPlans(integration_id));
            }

            onSuccess(response.data.plan);
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                onSuccess();
                displayError(e.response.data);
            }
        }
    };
};

// Own FF AWD shipments

export const getOwnFFAwdPlans = (integration_id: string, withLoading?: boolean): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            if (withLoading) {
                dispatch(SetLoadingStates({ isLoadingOwnFFPlans: true }));
            }

            const cookies = getState().auth.cookies;

            const response = await sendToAmazon.getOwnFFAwdPlans(cookies, integration_id);

            dispatch(SetOwnFFAwdPlans(integration_id, response.data.results));

            if (withLoading) {
                dispatch(SetLoadingStates({ isLoadingOwnFFPlans: false }));
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                if (withLoading) {
                    dispatch(SetLoadingStates({ isLoadingOwnFFPlans: false }));
                }

                displayError(e.response.data);
            }
        }
    };
};

export const deleteOwnFFAwdPlans = (integration_id: string, idsToDelete: string[]): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            await sendToAmazon.deleteOwnFFAwdPlans(cookies, idsToDelete);

            await dispatch(getOwnFFAwdPlans(integration_id));

            // onSuccess();
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

export const postProcessOwnFFToAmazonAwdLabels = (formData: FormData, integration_id: string, processStatus: (status: number) => void): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            const response = await sendToAmazon.postProcessOwnFFToAmazonAwdLabels(cookies, formData);

            await dispatch(getOwnFFAwdPlans(integration_id));

            processStatus(response.status);
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                processStatus(e.response.status);

                displayError(e.response.data, "An error occurred while processing AWD labels");
            }
        }
    };
};

export const postCreateOwnFFToAmazonAwdShipment = (integration_id: string, formData: FormData, onSuccess: () => void): SendToAmazonThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingMessage("We are sending your shipment information to the warehouse, if you have lots of SKUs this could take up to a minute."));
            dispatch(SetLoading(true));

            const cookies = getState().auth.cookies;

            await sendToAmazon.postCreateOwnFFToAmazonShipment(cookies, formData);

            onSuccess(); // reset own ff changes

            await dispatch(getFFDetails(cookies));
            await dispatch(getOwnFFAwdPlans(integration_id));

            dispatch(SetLoading(false));
            dispatch(SetLoadingMessage(null));

            displaySuccess("Shipment sent to the warehouse successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                dispatch(SetLoadingMessage(null));
                displayError(e.response.data, "An error occurred while sending the shipment plan to the warehouse");
            }
        }
    };
};

export default sendToAmazonReducer;
