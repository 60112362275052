import gb_flag from "../../assets/img/flags/flag_uk.png";
import usa_flag from "../../assets/img/flags/flag_usa.png";
import france_flag from "../../assets/img/flags/flag_france.png";
import germany_flag from "../../assets/img/flags/flag_germany.png";
import unknown_flag from "../../assets/img/flags/flag_unknown.png";

export function formatNumber(value: number, accuracy: number = 2) {
    const processedValue = parseFloat(value.toFixed(5));

    if (Number.isInteger(processedValue)) {
        return processedValue.toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    } else {
        return processedValue.toLocaleString("en-US", {
            minimumFractionDigits: accuracy,
            maximumFractionDigits: accuracy,
        });
    }
}

export function formatISODate(date: string) {
    const formattedDate = date.split("T")[0];
    const time = date.split("T")[1].split(".")[0].split(":").slice(0, 2).join(":");

    return `${formattedDate} ${time}`;
}

export function sortIntegrationList<T>(integrations: ({ country_code: string } & T)[]) {
    return integrations.sort((a, b) => (a.country_code === "US" ? -1 : b.country_code === "US" ? 1 : a.country_code.localeCompare(b.country_code))) || integrations;
}

export function processCountryFlag(country_code: string): string {
    if (["FR", "DE", "US", "UK"].includes(country_code)) {
        return {
            FR: france_flag,
            DE: germany_flag,
            US: usa_flag,
            UK: gb_flag,
        }[country_code];
    } else {
        return unknown_flag;
    }
}
