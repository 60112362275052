import Cookies from "universal-cookie";

import { instance } from "./authAPI";
import { processRequestQueryParams } from "./utils/apiUtils";
import { TSendToAmazonIntegration } from "../redux/types/SendToAmazon/sendToAmazon.types";
import { TGenericResponseFormat, TRequestQueryParams } from "../redux/types/common/common.types";
import {
    TLiveQuote,
    TLiveQuoteDetails,
    TPostCreateOffAmazonQuoteRequestData,
    TPostCreateToAmazonQuoteRequestData,
    TPostQuoteBulkUploadFileResponse,
    TRequestQuoteAmazonProduct,
    TRequestQuoteMarketplacesRegion,
} from "../redux/types/manualQuotingTypes/manualQuotingTypes";

export const manualQuotingTenantApi = {
    getLiveQuotes(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TLiveQuote>>(`/manual_quoting/quote-shipment/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getQuoteAvailableIntegrations(cookies: Cookies) {
        return instance.get<TGenericResponseFormat<TSendToAmazonIntegration>>(`/manual_quoting/integrations/available/?limit=20`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getRequestQuoteMarketplaces(cookies: Cookies) {
        return instance.get<{ marketplaces: TRequestQuoteMarketplacesRegion[] }>(`/manual_quoting/marketplaces/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getRequestQuoteProducts(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TRequestQuoteAmazonProduct>>(`/manual_quoting/quote-shipment/cartons/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postCreateQuoteRequest(cookies: Cookies, reqData: TPostCreateToAmazonQuoteRequestData | TPostCreateOffAmazonQuoteRequestData) {
        return instance.post(`/manual_quoting/quote-shipment-create/`, reqData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postUploadToAmazonLabels(cookies: Cookies, formData: FormData) {
        return instance.post(`/manual_quoting/labels-to-amazon/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postUploadOffAmazonLabels(cookies: Cookies, formData: FormData) {
        return instance.post(`/manual_quoting/labels-off-amazon/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchReviewLiveQuote(cookies: Cookies, id: string, data: { accepted_by_user?: true; rejected_by_user?: true }) {
        return instance.patch(`/manual_quoting/quote-shipment/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postQuoteBulkUploadFile(cookies: Cookies, formData: FormData) {
        return instance.post<TPostQuoteBulkUploadFileResponse>(`/manual_quoting/quote-orders-file-upload-csv/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Quote Details
    getLiveQuoteDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TLiveQuoteDetails>(`/manual_quoting/quote-details-shipment/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
