import axios from "axios";
import Cookies from "universal-cookie";

import {
    AffiliateCodeType,
    DeleteReasonDataType,
    DeleteRequestObject,
    SetAffilateCodeType,
    SetAllPaymentIssuessType,
    SetDeleteModType,
    SetDeleteReasonDataType,
    SetErrorType,
    SetErrorUserAlreadyExists,
    SetLoadingType,
    SetReportLoadingType,
    SetPaymentIssuessType,
    SettingsActionTypes,
    SetUrlForIntegrationType,
    SetUserAddressType,
    SetUserBillingAllType,
    SetUserBillingType,
    SetBillingReportType,
    SetUserIntegrationType,
    SetUserListType,
    SetUserPaymentMethodType,
    ThunkType,
    UserAddressType,
    UserBillingType,
    BillingReportType,
    UserIntegrationType,
    UsersListType,
    CreateBillingReportFileRequestData,
    TTenantRatesInfo,
    TSetTenantRatesInfo,
    TSetLoadingIntegrations,
    TSetLoadingTenantRates,
    TSetLoadingBilling,
    TSetLoadingBillingFailed,
    TSetPublicApiKeys,
    TSetLoadingPublicAPI,
    TSetLoadingPublicAPIModal,
    TPublicApiKey,
    TPostPublicApiReqData,
    UserPaymentsDueType,
    TSetUserPaymentDue,
    TSetUserPaymentOverdue,
    TPostChargeForPaymentGroup,
    TSetLoadingPaymentDue,
    TSetLoadingPaymentOverdue,
    TPastShipmentsState,
    TPastShipmentsFilter,
    TSettingsLoadingStates,
    TSetLoadingStates,
    TSetPastShipments,
    TSetPastShipmentsAll,
    TPastShipmentsResults,
    TPastShipmentsDetailsState,
    TPastShipmentsDetails,
    TPastShipmentsDetailsOptions,
    TPastShipmentsDetailsItems,
    TPostSubscriptionManagementReqData,
    TEmailSubscriptionState,
    TSetEmailSubscription,
} from "./types/SettingsTypes/settings.types";
import { amazon } from "../api/amazonAPI";
import { UserList } from "../api/userListAPI";
import { reportingApi } from "../api/reportingAPI";
import { userBilling } from "../api/userBillingAPI";
import { Auth, getUser, onLogOut } from "./authReducer";
import { userIntegration } from "../api/userIntegrationAPI";
import { PaymentMethodAPI, userProfile } from "../api/userProfileAPI";
import { AmazonUrlsType, UserType } from "./types/AuthTypes/auth.types";
import { displayError, displaySuccess } from "../hooks/useErrorHandler";
import { userPastShipmentsApi } from "../api/userPastShipmentsApi";
import { TRequestLazySearchParams } from "./types/common/common.types";
import { emailSubscriptionApi } from "../api/notificationAPI";

export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_USER_INTEGRATION = "SET_USER_INTEGRATION";
export const SET_USER_BILLING = "SET_USER_BILLING";
export const SET_USER_BILLING_ALL = "SET_USER_BILLING_ALL";
export const SET_USER_ADRESS = "SET_USER_ADRESS";
export const SET_USER_LIST = "SET_USER_LIST";
export const SET_ERROR_USER_ALREDY_EXIST = "SET_ERROR_USER_ALREDY_EXIST";
export const SET_URL_FOR_INTEGRATION = "SET_URL_FOR_INTEGRATION";
export const SET_USER_PAYMANT_METHOD = "SET_USER_PAYMANT_METHOD";
export const SET_DELETE_MOD = "SET_DELETE_MOD";
export const SET_DELETE_REASON_DATA = "SET_DELETE_REASON_DATA";
export const SET_AFFILIATE_CODES = "SET_AFFILIATE_CODES";
export const SET_PAYMANT_ISSUESS = "SET_PAYMANT_ISSUESS";
export const SET_ALL_PAYMANT_ISSUESS = "SET_ALL_PAYMANT_ISSUESS";
export const SET_REPORT_LOADING = "SET_REPORT_LOADING";
export const SET_BILLING_REPORT = "SET_BILLING_REPORT";
export const SET_TENANT_RATES_INFO = "SET_TENANT_RATES_INFO";
export const SET_PUBLIC_API_KEYS = "SET_PUBLIC_API_KEYS";
export const SET_USER_PAYMENT_DUE = "SET_USER_PAYMENT_DUE";
export const SET_USER_PAYMENT_OVERDUE = "SET_USER_PAYMENT_OVERDUE";
export const SET_LOADING_INTEGRATIONS = "SET_LOADING_INTEGRATIONS";
export const SET_LOADING_TENANT_RATES = "SET_LOADING_TENANT_RATES";
export const SET_LOADING_BILLING = "SET_LOADING_BILLING";
export const SET_LOADING_BILLING_FAILED = "SET_LOADING_BILLING_FAILED";
export const SET_LOADING_PUBLIC_API = "SET_LOADING_PUBLIC_API";
export const SET_LOADING_PUBLIC_API_MODAL = "SET_LOADING_PUBLIC_API_MODAL";
export const SET_LOADING_PAYMENT_DUE = "SET_LOADING_PAYMENT_DUE";
export const SET_LOADING_PAYMENT_OVERDUE = "SET_LOADING_PAYMENT_OVERDUE";
export const SET_PAST_SHIPMENTS = "SET_PAST_SHIPMENTS";
export const SET_PAST_SHIPMENTS_ALL = "SET_PAST_SHIPMENTS_ALL";
export const SET_LOADING_STATES = "SET_LOADING_STATES";
export const SET_PAST_SHIPMENTS_DETAILS = "SET_PAST_SHIPMENTS_DETAILS";
export const SET_EMAIL_SUBSCRIPTION = "SET_EMAIL_SUBSCRIPTION";

export type InitialStateType = {
    userIntegration: null | UserIntegrationType[];
    userBilling: null | UserBillingType[];
    userBillingAll: null | UserBillingType[];
    userAdress: null | UserAddressType;
    userList: null | UsersListType[];
    isLoading: boolean;
    isReportLoading: boolean;
    billingReport: null | BillingReportType;
    isError: boolean | null | string;
    addUserError: boolean | number;
    integrationUrl: null | AmazonUrlsType[];
    userPaymantMethod: null | string | object;
    deleteModal: boolean;
    deleteReasonData: null | DeleteReasonDataType;
    affiliateCodes: null | AffiliateCodeType;
    paymantIssueProducts: null | UserBillingType[];
    paymantAllIssueProducts: null | UserBillingType[];
    tenantRatesInfo: null | TTenantRatesInfo;
    publicApiKeys: null | TPublicApiKey[];
    userPaymentsDue: null | UserPaymentsDueType[];
    userPaymentsOverdue: null | UserPaymentsDueType[];
    isLoadingIntegrations: boolean;
    isLoadingTenantRates: boolean;
    isLoadingBilling: boolean;
    isLoadingBillingFailed: boolean;
    isLoadingShippedToAmazon: boolean;
    isLoadingPublicAPI: boolean;
    isLoadingPublicAPIModal: boolean;
    isLoadingPaymentDue: boolean;
    isLoadingPaymentOverdue: boolean;
    pastShipments: TPastShipmentsState;
    pastShipmentsAll: TPastShipmentsState;
    loadingStates: TSettingsLoadingStates;
    pastShipmentsDetails: TPastShipmentsDetailsState;
    emailSubscription: TEmailSubscriptionState;
};

let initialState: InitialStateType = {
    userIntegration: null,
    userBilling: null,
    userBillingAll: null,
    billingReport: null,
    userAdress: null,
    userList: null,
    isLoading: false,
    isReportLoading: false,
    isError: false,
    addUserError: false,
    integrationUrl: null,
    userPaymantMethod: null,
    deleteModal: false,
    deleteReasonData: null,
    affiliateCodes: null,
    paymantIssueProducts: null,
    paymantAllIssueProducts: null,
    tenantRatesInfo: null,
    publicApiKeys: null,
    userPaymentsDue: null,
    userPaymentsOverdue: null,
    isLoadingIntegrations: false,
    isLoadingTenantRates: false,
    isLoadingBilling: false,
    isLoadingBillingFailed: false,
    isLoadingShippedToAmazon: false,
    isLoadingPublicAPI: false,
    isLoadingPublicAPIModal: false,
    isLoadingPaymentDue: false,
    isLoadingPaymentOverdue: false,
    pastShipments: {
        inbound: null,
        skudrop: null,
        own_ff: null,
        quote: null,
    },
    pastShipmentsAll: {
        inbound: null,
        skudrop: null,
        own_ff: null,
        quote: null,
    },
    loadingStates: {
        isLoadingPastInboundShipments: false,
        isLoadingPastSKUdropShipments: false,
        isLoadingPastOwnFFShipments: false,
        isLoadingPastQuoteShipments: false,
        isLoadingPastQuoteShipmentDetails: false,
        isLoadingEmailSubscription: false,
        isLoadingEmailSubscriptionManagement: false,
        isLoadingReporting: false,
    },
    pastShipmentsDetails: {
        quote: null,
    },
    emailSubscription: {
        emails: null,
        management: null,
    },
};

const settingsProductReducer = (state = initialState, action: SettingsActionTypes): InitialStateType => {
    switch (action.type) {
        case SET_AFFILIATE_CODES: {
            return {
                ...state,
                affiliateCodes: action.data,
            };
        }
        case SET_URL_FOR_INTEGRATION: {
            return {
                ...state,
                integrationUrl: action.data,
            };
        }
        case SET_USER_ADRESS: {
            return {
                ...state,
                userAdress: action.data,
            };
        }
        case SET_USER_INTEGRATION: {
            return {
                ...state,
                userIntegration: action.data,
            };
        }
        case SET_USER_BILLING: {
            return {
                ...state,
                userBilling: action.data,
            };
        }
        case SET_BILLING_REPORT: {
            return {
                ...state,
                billingReport: action.data,
            };
        }
        case SET_PAYMANT_ISSUESS: {
            return {
                ...state,
                paymantIssueProducts: action.data,
            };
        }
        case SET_ALL_PAYMANT_ISSUESS: {
            return {
                ...state,
                paymantAllIssueProducts: action.data,
            };
        }
        case SET_USER_BILLING_ALL: {
            return {
                ...state,
                userBillingAll: action.data,
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_REPORT_LOADING: {
            return {
                ...state,
                isReportLoading: action.data,
            };
        }
        case SET_USER_LIST: {
            return {
                ...state,
                userList: action.data,
            };
        }
        case SET_ERROR: {
            return {
                ...state,
                isError: action.data,
            };
        }
        case SET_ERROR_USER_ALREDY_EXIST: {
            return {
                ...state,
                addUserError: action.data,
            };
        }
        case SET_USER_PAYMANT_METHOD: {
            return {
                ...state,
                userPaymantMethod: action.data,
            };
        }
        case SET_DELETE_MOD: {
            return {
                ...state,
                deleteModal: action.data,
            };
        }
        case SET_DELETE_REASON_DATA: {
            return {
                ...state,
                deleteReasonData: action.data,
            };
        }
        case SET_TENANT_RATES_INFO: {
            return {
                ...state,
                tenantRatesInfo: action.data,
            };
        }
        case SET_PUBLIC_API_KEYS: {
            return {
                ...state,
                publicApiKeys: action.data,
            };
        }
        case SET_USER_PAYMENT_DUE: {
            return {
                ...state,
                userPaymentsDue: action.data,
            };
        }
        case SET_USER_PAYMENT_OVERDUE: {
            return {
                ...state,
                userPaymentsOverdue: action.data,
            };
        }
        case SET_LOADING_INTEGRATIONS: {
            return {
                ...state,
                isLoadingIntegrations: action.data,
            };
        }
        case SET_LOADING_TENANT_RATES: {
            return {
                ...state,
                isLoadingTenantRates: action.data,
            };
        }
        case SET_LOADING_BILLING: {
            return {
                ...state,
                isLoadingBilling: action.data,
            };
        }
        case SET_LOADING_BILLING_FAILED: {
            return {
                ...state,
                isLoadingBillingFailed: action.data,
            };
        }
        case SET_LOADING_PUBLIC_API: {
            return {
                ...state,
                isLoadingPublicAPI: action.data,
            };
        }
        case SET_LOADING_PUBLIC_API_MODAL: {
            return {
                ...state,
                isLoadingPublicAPIModal: action.data,
            };
        }
        case SET_LOADING_PAYMENT_DUE: {
            return {
                ...state,
                isLoadingPaymentDue: action.data,
            };
        }
        case SET_LOADING_PAYMENT_OVERDUE: {
            return {
                ...state,
                isLoadingPaymentOverdue: action.data,
            };
        }
        case SET_PAST_SHIPMENTS: {
            return {
                ...state,
                pastShipments: { ...state.pastShipments, [action.option]: action.data },
            };
        }
        case SET_PAST_SHIPMENTS_ALL: {
            return {
                ...state,
                pastShipmentsAll: { ...state.pastShipmentsAll, [action.option]: action.data },
            };
        }
        case SET_LOADING_STATES: {
            return {
                ...state,
                loadingStates: { ...state.loadingStates, ...action.data },
            };
        }
        case SET_PAST_SHIPMENTS_DETAILS: {
            return {
                ...state,
                pastShipmentsDetails: { ...state.pastShipmentsDetails, [action.option]: action.data },
            };
        }
        case SET_EMAIL_SUBSCRIPTION: {
            return {
                ...state,
                emailSubscription: { ...state.emailSubscription, ...action.data },
            };
        }
        default:
            return state;
    }
};
export const SetAffiliateCodes = (data: AffiliateCodeType): SetAffilateCodeType => ({
    type: SET_AFFILIATE_CODES,
    data,
});

export const SetUserAdress = (data: UserAddressType): SetUserAddressType => ({
    type: SET_USER_ADRESS,
    data,
});
export const SetUserIntegration = (data: UserIntegrationType[]): SetUserIntegrationType => ({
    type: SET_USER_INTEGRATION,
    data,
});
export const SetUserBilling = (data: UserBillingType[]): SetUserBillingType => ({
    type: SET_USER_BILLING,
    data,
});
export const SetPaymentIssuess = (data: UserBillingType[]): SetPaymentIssuessType => ({
    type: SET_PAYMANT_ISSUESS,
    data,
});
export const SetAllPaymentIssuess = (data: UserBillingType[]): SetAllPaymentIssuessType => ({
    type: SET_ALL_PAYMANT_ISSUESS,
    data,
});
export const SetUserBillingAll = (data: UserBillingType[]): SetUserBillingAllType => ({
    type: SET_USER_BILLING_ALL,
    data,
});
export const SetBillingReport = (data: BillingReportType): SetBillingReportType => ({
    type: SET_BILLING_REPORT,
    data,
});

export const SetUserList = (data: UsersListType[]): SetUserListType => ({
    type: SET_USER_LIST,
    data: data,
});
export const SetLoading = (loading: boolean): SetLoadingType => ({
    type: SET_LOADING,
    data: loading,
});
export const SetReportLoading = (loading: boolean): SetReportLoadingType => ({
    type: SET_REPORT_LOADING,
    data: loading,
});
export const SetError = (error: string | null | boolean): SetErrorType => ({
    type: SET_ERROR,
    data: error,
});
export const SetErrorUserAlredyExist = (data: boolean | number): SetErrorUserAlreadyExists => ({
    type: SET_ERROR_USER_ALREDY_EXIST,
    data: data,
});
export const SetUrlForIntegration = (data: AmazonUrlsType[]): SetUrlForIntegrationType => ({
    type: SET_URL_FOR_INTEGRATION,
    data,
});

export const SetUserPaymant = (field: string | object): SetUserPaymentMethodType => ({
    type: SET_USER_PAYMANT_METHOD,
    data: field,
});
export const SetDeleteModal = (data: boolean): SetDeleteModType => ({
    type: SET_DELETE_MOD,
    data,
});
export const SetDeleteReasonData = (data: DeleteReasonDataType): SetDeleteReasonDataType => ({
    type: SET_DELETE_REASON_DATA,
    data,
});
export const SetTenantRatesInfo = (data: TTenantRatesInfo): TSetTenantRatesInfo => ({
    type: SET_TENANT_RATES_INFO,
    data,
});
export const SetLoadingIntegrations = (data: boolean): TSetLoadingIntegrations => ({
    type: SET_LOADING_INTEGRATIONS,
    data,
});
export const SetLoadingTenantRates = (data: boolean): TSetLoadingTenantRates => ({
    type: SET_LOADING_TENANT_RATES,
    data,
});
export const SetLoadingBilling = (data: boolean): TSetLoadingBilling => ({
    type: SET_LOADING_BILLING,
    data,
});
export const SetLoadingBillingFailed = (data: boolean): TSetLoadingBillingFailed => ({
    type: SET_LOADING_BILLING_FAILED,
    data,
});

export const SetPublicApiKeys = (data: TPublicApiKey[]): TSetPublicApiKeys => ({
    type: SET_PUBLIC_API_KEYS,
    data,
});

export const SetUserPaymentsDue = (data: UserPaymentsDueType[]): TSetUserPaymentDue => ({
    type: SET_USER_PAYMENT_DUE,
    data,
});

export const SetUserPaymentsOverdue = (data: UserPaymentsDueType[]): TSetUserPaymentOverdue => ({
    type: SET_USER_PAYMENT_OVERDUE,
    data,
});
export const SetLoadingPublicAPI = (data: boolean): TSetLoadingPublicAPI => ({
    type: SET_LOADING_PUBLIC_API,
    data,
});

export const SetLoadingPublicAPIModal = (data: boolean): TSetLoadingPublicAPIModal => ({
    type: SET_LOADING_PUBLIC_API_MODAL,
    data,
});

export const SetLoadingPaymentDue = (data: boolean): TSetLoadingPaymentDue => ({
    type: SET_LOADING_PAYMENT_DUE,
    data,
});

export const SetLoadingPaymentOverdue = (data: boolean): TSetLoadingPaymentOverdue => ({
    type: SET_LOADING_PAYMENT_OVERDUE,
    data,
});

export const SetPastShipments = (option: TPastShipmentsFilter, data: TPastShipmentsResults[]): TSetPastShipments => ({
    type: SET_PAST_SHIPMENTS,
    option,
    data,
});

export const SetPastShipmentsAll = (option: TPastShipmentsFilter, data: TPastShipmentsResults[]): TSetPastShipmentsAll => ({
    type: SET_PAST_SHIPMENTS_ALL,
    option,
    data,
});

export const SetLoadingStates = (data: Partial<{ [key in keyof TSettingsLoadingStates]: boolean }>): TSetLoadingStates => ({
    type: SET_LOADING_STATES,
    data,
});

export const SetPastShipmentsDetails = (option: TPastShipmentsDetailsOptions, data: TPastShipmentsDetailsItems): TPastShipmentsDetails => ({
    type: SET_PAST_SHIPMENTS_DETAILS,
    option,
    data,
});

export const SetEmailSubscription = (data: Partial<TEmailSubscriptionState>): TSetEmailSubscription => ({
    type: SET_EMAIL_SUBSCRIPTION,
    data,
});

export const patchIntegrationName = (cookies: Cookies, integration_name: string, integration_id: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await userIntegration.patchIntegrationName(cookies, integration_name, integration_id);
            console.log(response.data);
            dispatch(getUserIntegration(cookies));
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while updating the integration");
            }
        }
    };
};

export const getUrlForIntegration = (): ThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoading(true));

            const cookies = getState().auth.cookies;

            let response = await amazon.getUrlForIntegration(cookies);

            let integrations = Object.entries(response.data).map(([key, value]) => ({ name: key, link: value }));

            dispatch(SetUrlForIntegration(integrations));

            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getUserIntegration = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingIntegrations(true));
            const response = await userIntegration.getUserIntegration(cookies);
            console.log(response.data);
            dispatch(SetUserIntegration(response.data.results));
            dispatch(SetLoadingIntegrations(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const deleteIntegration = (cookies: Cookies, id: string, status: string, handleError: (data: any) => void): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await userIntegration.actionIntegration(cookies, id, status);
            console.log(response.data);
            dispatch(getUser(cookies));
            dispatch(getUserIntegration(cookies));
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                handleError(e.response.data);
                displayError(e.response.data, "Something went wrong while deleting the integration");
            }
        }
    };
};

export const activeIntegration = (cookies: Cookies, id: string, status: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await userIntegration.actionIntegration(cookies, id, status);
            console.log(response.data);
            dispatch(getUser(cookies));
            dispatch(getUserIntegration(cookies));
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while activating the integration");
            }
        }
    };
};

export const onManualPayment = (cookies: Cookies, id: string, handleError: (data: any) => void): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await PaymentMethodAPI.onManualPayment(cookies, id);
            console.log(response.data);
            dispatch(getUserBilling(cookies, "succeeded"));
            dispatch(getUserBilling(cookies, "requires_action"));
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                handleError(e.response.data);
                displayError(e.response.data, "Something went wrong during the payment process");
            }
        }
    };
};

export const onStripeConfirm = (cookies: Cookies, payment_intent: string, redirect_status: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await PaymentMethodAPI.onStripeConfirm(cookies, payment_intent, redirect_status);
            console.log(response.data);
            dispatch(getUserBilling(cookies, "succeeded"));
            dispatch(getUserBilling(cookies, "requires_action"));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getUserBilling = (cookies: Cookies, status: string, sortBy?: string | null, searchValue?: string | null, dateFrom?: string, dateTo?: string): ThunkType => {
    return async (dispatch) => {
        try {
            if (status === "succeeded") {
                dispatch(SetLoadingBilling(true));
            } else {
                dispatch(SetLoadingBillingFailed(true));
            }
            const response = await userBilling.getUserBilling(cookies, status, sortBy, searchValue, dateFrom, dateTo);
            if (status === "succeeded") {
                dispatch(SetUserBilling(response.data.results));
                dispatch(SetLoadingBilling(false));
            } else {
                dispatch(SetPaymentIssuess(response.data.results));
                dispatch(SetLoadingBillingFailed(false));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const getUserBillingAll = (
    cookies: Cookies,
    status: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void,
    sortBy?: string | null,
    searchValue?: string | null,
    dateFrom?: string,
    dateTo?: string
): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await userBilling.getUserBillingAll(cookies, status, items, offset, sortBy, searchValue, dateFrom, dateTo);
            console.log(response.data);
            setProductAllNext(response.data.next !== null ? true : false);
            if (status === "succeeded") {
                dispatch(SetUserBillingAll(response.data.results));
            } else {
                dispatch(SetAllPaymentIssuess(response.data.results));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const changePassword = (cookies: Cookies, newPassword: string, currentPassword: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await userProfile.changePassword(cookies, newPassword, currentPassword);
            console.log(response.data);
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while changing the password");
            }
        }
    };
};

export const changeUserDetails = (cookies: Cookies, userId: string, firstName: string, lastName: string, email: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await userProfile.changeUserDetails(cookies, userId, email, firstName, lastName);
            console.log(response.data);
            dispatch(Auth(cookies));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
            if (axios.isAxiosError(e)) {
                if (e.response && e.response.status === 400) {
                    displayError("User with this email already exists.");
                    dispatch(SetLoading(false));
                }
            }
        }
    };
};

export const addAffiliateCode = (cookies: Cookies, id: string, tenantId: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await userProfile.addAffiliateCode(cookies, id, tenantId);
            console.log(response.data);
            dispatch(getAffiliatesCodes(cookies));
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while creating the affiliate code");
            }
        }
    };
};

export const getAffiliatesCodes = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await userProfile.getAffiliatesCodes(cookies);
            console.log(response.data);
            if (response.data && !response.data.message) {
                dispatch(SetAffiliateCodes(response.data));
            }
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const checkAffiliateCode = (cookies: Cookies, code: string, user: UserType): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await userProfile.checkAffiliateCode(cookies, code);
            console.log(response.data);
            await dispatch(addAffiliateCode(cookies, response.data.id, user.get_current_tenant_id));
            if (user.balance > 0) {
                dispatch(getUser(cookies));
            }
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
            if (axios.isAxiosError(e)) {
                if (e.response && e.response.status === 404) {
                    displayError("Code does not exist");
                    dispatch(SetLoading(false));
                } else if (e.response && e.response?.data) {
                    displayError(e.response.data);
                    dispatch(SetLoading(false));
                }
            }
        }
    };
};

export const deleteAccount = (cookies: Cookies, id: string, data: DeleteRequestObject): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await userProfile.deleteAccount(cookies, id, data);
            console.log(response.data);
            dispatch(Auth(cookies));
            dispatch(SetError(null));
            dispatch(onLogOut(cookies));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
            if (axios.isAxiosError(e)) {
                if (e.response && e.response.status === 400) {
                    console.log("error 400");
                    dispatch(SetError("setShowErrorModal"));
                    dispatch(SetLoading(false));
                }
            }
        }
    };
};
export const disableAccount = (cookies: Cookies, id: string, data: DeleteRequestObject): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            console.log("data request patch", data);
            const response = await userProfile.disableAccount(cookies, id, data);
            console.log(response.data);
            dispatch(SetError(null));
            dispatch(Auth(cookies));
            dispatch(SetDeleteModal(false));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
            if (axios.isAxiosError(e)) {
                if (e.response && e.response.status === 400) {
                    displayError(e.response.data["message"]);
                    dispatch(SetLoading(false));
                }
            }
        }
    };
};
export const deleteAccountCheck = (cookies: Cookies, id: string, data: DeleteReasonDataType): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await userProfile.deleteAccountCheck(cookies, id);
            dispatch(SetDeleteReasonData(data));
            console.log(response.data);
            dispatch(SetDeleteModal(true));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
            if (axios.isAxiosError(e)) {
                if (e.response && e.response.status === 400) {
                    console.log("error 400");
                    dispatch(SetError("setShowErrorModal"));
                    dispatch(SetLoading(false));
                }
            }
        }
    };
};

export const activateAccount = (cookies: Cookies, id: string, data: DeleteReasonDataType): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await userProfile.activateAccount(cookies, id, data);
            console.log(response.data);
            dispatch(SetError("updateCreditCard"));
            dispatch(Auth(cookies));
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while activating account");
            }
        }
    };
};

export const getUserAdress = (cookies: Cookies, userId: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await userProfile.getUserAdress(cookies, userId);
            console.log(response.data);
            dispatch(SetUserAdress(response.data));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};
export const setUserAdress = (
    cookies: Cookies,
    tenantID: string,
    billing_line1: string,
    billing_postal_code: string,
    billing_city: string,
    billing_state: string,
    billing_country: string,
    phone_number: string
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await userProfile.setUserAdress(cookies, tenantID, billing_line1, billing_postal_code, billing_city, billing_state, billing_country, phone_number);
            console.log(response.data);
            dispatch(getUserAdress(cookies, tenantID));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getUserList = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await UserList.getUserList(cookies);
            dispatch(SetUserList(response.data.results));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const AddUserToTenant = (userEmail: string, cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await UserList.AddUserToTenant(userEmail, cookies);
            dispatch(getUserList(cookies));
            console.log(response);
            dispatch(SetErrorUserAlredyExist(false));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
            if (axios.isAxiosError(e) && e.response) {
                if (e.response.status === 400) {
                    dispatch(SetErrorUserAlredyExist(e.response.status));
                } else {
                    displayError(e.response.data);
                }
                dispatch(SetLoading(false));
            }
        }
    };
};

export const ConfirmAddedUserToTenant = (uuid: string, userToken: string, password: string, cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await UserList.ConfirmAddedUserToTenant(uuid, userToken, password);
            console.log(response);
            displaySuccess(response.data.text);
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while adding a user to the profile");
            }
        }
    };
};

export const DeleteUserFromTennant = (email: string, cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await UserList.DeleteUserFromTennant(email, cookies);
            dispatch(getUserList(cookies));
            console.log(response);
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while deleting a user from the profile");
            }
        }
    };
};
export const onPaymentConfirm = (
    payment_intent: string,
    payment_intent_client_secret: string,
    redirect_status: string,
    setPaymentConfirm: (state: boolean) => void,
    cookies: Cookies
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await PaymentMethodAPI.onPaymentConfirm(cookies, payment_intent, payment_intent_client_secret, redirect_status);
            console.log(response);
            setPaymentConfirm(true);
            dispatch(Auth(cookies));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getPaymatMethod = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await PaymentMethodAPI.getPaymatMethod(cookies);
            dispatch(SetUserPaymant(response));
            console.log(response);
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const updatePaymantMethod = (cookies: Cookies, stripe_pm_id: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            console.log("updatePaymantMethod2");
            const response = await PaymentMethodAPI.updatePaymantMethod(cookies, stripe_pm_id);
            dispatch(Auth(cookies));
            dispatch(getPaymatMethod(cookies));
            console.log(response);
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while updating the payment method");
            }
        }
    };
};

export const getBillingReport = (cookies: Cookies, dateFrom?: string, dateTo?: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetReportLoading(true));
            const response = await userBilling.getBillingReport(cookies, dateFrom, dateTo);
            console.log(response.data);
            dispatch(SetBillingReport(response.data));
            dispatch(SetReportLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const createBillingReportFile = (cookies: Cookies, data: CreateBillingReportFileRequestData): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetReportLoading(true));
            const response = await userBilling.createBillingReportFile(cookies, data);
            if (response) {
                window.open(response.data.url);
            }
            dispatch(SetReportLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetReportLoading(false));
                displayError(e.response.data, "Something went wrong while creating the billing report file");
            }
        }
    };
};

export const changeBillingAccess = (cookies: Cookies, userData: UsersListType, is_staff: boolean): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await UserList.changeBillingAccess(cookies, userData.id, is_staff);
            if (response.status === 200) {
                if (is_staff) {
                    displaySuccess(`${userData.email} has gained billing access`);
                } else {
                    displaySuccess(`${userData.email} has lost billing access`);
                }
            }
            dispatch(getUserList(cookies));
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while changing billing access");
            }
        }
    };
};

export const getTenantRatesInfo = (cookies: Cookies, tenantId: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingTenantRates(true));
            const response = await userBilling.getTenantRatesInfo(cookies, tenantId);
            dispatch(SetTenantRatesInfo(response.data));
            dispatch(SetLoadingTenantRates(false));
        } catch (e) {
            console.log(e);
        }
    };
};

// Public API

export const getPublicApiKeys = (cookies: Cookies, disableLoading?: boolean): ThunkType => {
    return async (dispatch) => {
        try {
            if (!disableLoading) {
                dispatch(SetLoadingPublicAPI(true));
            }

            const response = await userProfile.getPublicApiKeys(cookies);
            dispatch(SetPublicApiKeys(response.data.results));

            if (!disableLoading) {
                dispatch(SetLoadingPublicAPI(false));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const postCreatePublicApiKey = (cookies: Cookies, reqData: TPostPublicApiReqData, onSuccess: (key: string) => void): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingPublicAPIModal(true));

            const response = await userProfile.postCreatePublicApiKey(cookies, reqData);
            await dispatch(getPublicApiKeys(cookies, true));

            onSuccess(response.data.key_value);

            dispatch(SetLoadingPublicAPIModal(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingPublicAPIModal(false));
                displayError(e.response.data, "Something went wrong while creating the API key");
            }
        }
    };
};

export const deletePublicApiKey = (cookies: Cookies, id: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingPublicAPI(true));

            await userProfile.deleteApiKey(cookies, id);
            dispatch(getPublicApiKeys(cookies));

            dispatch(SetLoadingPublicAPI(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "Something went wrong while deleting the API key");
            }
        }
    };
};

export const patchUpdatePublicApiKey = (cookies: Cookies, id: string, key_expiration: string | undefined, onSuccess: () => void): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingPublicAPIModal(true));

            await userProfile.patchUpdatePublicApiKey(cookies, id, key_expiration);
            await dispatch(getPublicApiKeys(cookies, true));

            onSuccess();

            dispatch(SetLoadingPublicAPIModal(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingPublicAPIModal(false));
                displayError(e.response.data, "Something went wrong while changing the Expiration date");
            }
        }
    };
};

export const getUserPaymentsDue = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingPaymentDue(true));
            const response = await userBilling.getUserPaymentsDue(cookies);
            dispatch(SetUserPaymentsDue(response.data.results));
            dispatch(SetLoadingPaymentDue(false));
        } catch (e) {
            dispatch(SetLoadingPaymentDue(false));
            console.log(e);
        }
    };
};

export const getUserPaymentsOverdue = (cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingPaymentOverdue(true));
            const response = await userBilling.getUserPaymentsOverdue(cookies);
            dispatch(SetUserPaymentsOverdue(response.data.results));
            dispatch(SetLoadingPaymentOverdue(false));
        } catch (e) {
            dispatch(SetLoadingPaymentOverdue(false));
            console.log(e);
        }
    };
};

export const postChargeForPaymentGroup = (data: TPostChargeForPaymentGroup, updateBilling: () => void): ThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingPaymentDue(true));

            const response = await userBilling.postChargeForPaymentGroup(cookies, data);

            if (data.payment_method === "xero") {
                window.open(response.data);
            }

            updateBilling();

            dispatch(getUserPaymentsDue(cookies));
            dispatch(getUserPaymentsOverdue(cookies));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingPaymentDue(false));
                displayError(e.response.data, "Something went wrong while charging the payment group");
            }
        }
    };
};

// Past Shipments

export const getPastShipments = (option: TPastShipmentsFilter, queryParams: Partial<{ date_from: string; date_to: string }>): ThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            if (option === "inbound") {
                dispatch(SetLoadingStates({ isLoadingPastInboundShipments: true }));

                const response = await userPastShipmentsApi.getPastInboundShipments(cookies, queryParams);
                dispatch(SetPastShipments(option, response.data.results));

                dispatch(SetLoadingStates({ isLoadingPastInboundShipments: false }));
            } else if (option === "skudrop") {
                dispatch(SetLoadingStates({ isLoadingPastSKUdropShipments: true }));

                const response = await userPastShipmentsApi.getPastSKUdropShipments(cookies, queryParams);
                dispatch(SetPastShipments(option, response.data.results));

                dispatch(SetLoadingStates({ isLoadingPastSKUdropShipments: false }));
            } else if (option === "own_ff") {
                dispatch(SetLoadingStates({ isLoadingPastOwnFFShipments: true }));

                const response = await userPastShipmentsApi.getPastOwnFFShipments(cookies, queryParams);
                dispatch(SetPastShipments(option, response.data.results));

                dispatch(SetLoadingStates({ isLoadingPastOwnFFShipments: false }));
            } else if (option === "quote") {
                dispatch(SetLoadingStates({ isLoadingPastQuoteShipments: true }));

                const response = await userPastShipmentsApi.getPastQuoteShipments(cookies, queryParams);
                dispatch(SetPastShipments(option, response.data.results));

                dispatch(SetLoadingStates({ isLoadingPastQuoteShipments: false }));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const getPastShipmentsAll = (
    option: TPastShipmentsFilter,
    queryParams: TRequestLazySearchParams & Partial<{ date_from: string; date_to: string }>,
    setProductsAllNext: (data: boolean) => void
): ThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            if (option === "inbound") {
                const response = await userPastShipmentsApi.getPastInboundShipments(cookies, queryParams);

                setProductsAllNext(!!response.data.next);

                dispatch(SetPastShipmentsAll(option, response.data.results));
            } else if (option === "skudrop") {
                const response = await userPastShipmentsApi.getPastSKUdropShipments(cookies, queryParams);

                setProductsAllNext(!!response.data.next);

                dispatch(SetPastShipmentsAll(option, response.data.results));
            } else if (option === "own_ff") {
                const response = await userPastShipmentsApi.getPastOwnFFShipments(cookies, queryParams);

                setProductsAllNext(!!response.data.next);

                dispatch(SetPastShipmentsAll(option, response.data.results));
            } else if (option === "quote") {
                const response = await userPastShipmentsApi.getPastQuoteShipments(cookies, queryParams);

                setProductsAllNext(!!response.data.next);

                dispatch(SetPastShipmentsAll(option, response.data.results));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

// Past Shipments Details

export const getPastShipmentsDetails = (option: TPastShipmentsDetailsOptions, queryParams: { id: string }): ThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            if (option === "quote") {
                dispatch(SetLoadingStates({ isLoadingPastQuoteShipmentDetails: true }));

                const response = await userPastShipmentsApi.getPastQuoteShipmentDetails(cookies, queryParams);

                dispatch(SetPastShipmentsDetails(option, response.data));

                dispatch(SetLoadingStates({ isLoadingPastQuoteShipmentDetails: false }));
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                if (option === "quote") {
                    dispatch(SetLoadingStates({ isLoadingPastQuoteShipmentDetails: false }));
                }

                displayError(e.response.data);
            }
        }
    };
};

// User Reporting

export const postGenerateStorageOverviewReport = (): ThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingReporting: true }));

            const response = await reportingApi.postGenerateStorageOverviewReport(cookies);

            window.open(response.data.url, "_blank");

            dispatch(SetLoadingStates({ isLoadingReporting: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingReporting: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const postGenerateStorageReport = (type: "short" | "extended", reqData: { date_from: string; date_to: string }): ThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingReporting: true }));

            if (type === "short") {
                const response = await reportingApi.postGenerateShortStorageReport(cookies, reqData);

                window.open(response.data.url, "_blank");
            } else {
                const response = await reportingApi.postGenerateExtendedStorageReport(cookies, reqData);

                window.open(response.data.url, "_blank");
            }

            dispatch(SetLoadingStates({ isLoadingReporting: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingReporting: false }));
                displayError(e.response.data);
            }
        }
    };
};

// Email Subscriptions

export const getTenantMemberEmails = (): ThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingEmailSubscription: true }));

            const { cookies } = getState().auth;

            const response = await emailSubscriptionApi.getTenantMemberEmails(cookies);

            dispatch(SetEmailSubscription({ emails: response.data }));

            dispatch(SetLoadingStates({ isLoadingEmailSubscription: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getEmailSubscriptionManagement = (): ThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingEmailSubscriptionManagement: true }));

            const { cookies } = getState().auth;

            const response = await emailSubscriptionApi.getEmailSubscriptionManagement(cookies);

            dispatch(SetEmailSubscription({ management: response.data }));

            dispatch(SetLoadingStates({ isLoadingEmailSubscriptionManagement: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postEmailSubscriptionManagement = (reqData: TPostSubscriptionManagementReqData[]): ThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingEmailSubscriptionManagement: true }));

            const { cookies } = getState().auth;

            await emailSubscriptionApi.postEmailSubscriptionManagement(cookies, reqData);

            displaySuccess("Notification settings have been updated");

            dispatch(getEmailSubscriptionManagement());
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingEmailSubscriptionManagement: false }));
                displayError(e.response.data);
            }
        }
    };
};

export default settingsProductReducer;
